import apiList from "./api-list";
import axios from "axios";
import popup from "$utils/tool/popup";
// import { Toast } from "antd-mobile";
// import { camelToSnake, snakeToCamel } from "@/utils";

/**
 * 将对象的键名从驼峰转下划线
 * @param {Object} obj { blackBox: '123', anotherKey: [{ nestedKey: '456' }] };
 * @returns {} { black_box: '123', another_key: [{ nested_key: '456' }] };
 */
export function camelToSnake(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(camelToSnake);
  }

  const snakeCaseObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeCaseKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
      snakeCaseObj[snakeCaseKey] = camelToSnake(obj[key]);
    }
  }

  return snakeCaseObj;
}

/**
 * 将对象的键名从下划线转小驼峰
 * @param {Object} obj { black_box: '123', another_key: { nested_key: '456' } };
 * @returns {}  { blackBox: '123', anotherKey: { nestedKey: '456' } };
 */
export function snakeToCamel(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(snakeToCamel);
  }

  const camelCaseObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
        letter.toUpperCase()
      );
      camelCaseObj[camelCaseKey] = snakeToCamel(obj[key]);
    }
  }

  return camelCaseObj;
}

// 创建 axios 实例 entity
let axiosEntity = axios.create();

const nzHttp = (apiKey, userConfig) => {
  // const nzHttp = (apiKey:keyof typeof apiList, userConfig?:any)=>{
  const api = apiList[apiKey];
  if (!api) {
    return new Promise((resolve, reject) => {
      reject();
    });
  }
  // const plugin = api.length>=2?api[2]:undefined;
  let method = api[1];
  let url = api[0];
  let configData = api[2];
  let uConfig = userConfig || {
    data: {},
    headers: {},
    disabled: {}, // 禁用 header 的某键 或 data的某键
  };
  /**
   * 如果target(也就是FirstOBJ[key])存在，
   * 且是对象的话再去调用deepObjectMerge，
   * 否则就是FirstOBJ[key]里面没这个对象，需要与SecondOBJ[key]合并
   */
  function deepObjectMerge(FirstOBJ, SecondOBJ) {
    // 深度合并对象
    for (var key in SecondOBJ) {
      FirstOBJ[key] =
        FirstOBJ[key] && FirstOBJ[key].toString() === "[object Object]"
          ? deepObjectMerge(FirstOBJ[key], SecondOBJ[key])
          : (FirstOBJ[key] = SecondOBJ[key]);
    }
    return FirstOBJ;
  }
  deepObjectMerge(uConfig, configData);

  if (typeof method == "object") {
    if (method.indexOf("path") != "-1") {
      if (uConfig.pathData != undefined) {
        url = url + "/" + userConfig.pathData;
      } else if ((userConfig || {}).data != undefined) {
        // 将第一个参数拼到url上..
        for (let key in userConfig.data) {
          let data = userConfig.data[key];
          url = url + "/" + data;
          break;
        }
      }

      // if(uConfig.data!=undefined){
      //     const keys = Object.keys(uConfig.data) as (keyof typeof uConfig.data)[];
      //     url += ( '/' + uConfig.data[keys[0]] )
      // }
      method = method[0];
    }
  }

  let dfConfig = {
    url: url,
    method: method,
    headers: {},
    unError: false,
  };

  dfConfig.headers.token = localStorage.getItem("nz_token");

  if (uConfig.headers) {
    for (const key in uConfig.headers) {
      if (Object.prototype.hasOwnProperty.call(uConfig.headers, key)) {
        const item = uConfig.headers[key];
        if (item === undefined) {
          // 如果 参数传 undefined 则 禁用此选项
          delete dfConfig.headers[key];
        } else {
          dfConfig.headers[key] = item;
        }
      }
    }
    delete uConfig.headers;
  }

  const Data = camelToSnake(uConfig.data);
  if (method == "get") {
    dfConfig.params = Data;
  } else {
    dfConfig.data = Data;
  }

  return new Promise(function (resolve, reject) {
    // res:{code:number, msg:string, data?:any }
    axiosEntity
      .request(dfConfig)
      .then((res) => {
        let status = res.status;
        if (status == 200) {
          let data = snakeToCamel(res.data);
          if (data.code == 0) {
            // console.log("🚀 ~ file: index.js:98 ~ axiosEntity.request ~ res.code:", res.code)
            return resolve(data.data);
          }else if(data.code == 1){
            if (!dfConfig.unError) {
              // Toast.error(res.msg)
              popup.$toast(data.msg ?? "网络连接失败",'error');
              // Toast.show({
              //   icon: "fail",
              //   content: res.msg ?? "网络连接失败",
              // });
            }
          }
        } else if (status == 500 || status == 502) {
          if (!dfConfig.unError) {
            // Toast.error(res.msg)
            popup.$toast(res.msg ?? "网络连接失败",'error');
            // Toast.show({
            //   icon: "fail",
            //   content: res.msg ?? "网络连接失败",
            // });
          }
          return reject(res);
        } else if (status == 401) {
          if (!dfConfig.unError) {
            // Toast.error(res.msg)
            popup.$toast(res.msg ?? "登录过期",'error');
            // Toast.show({
            //   icon: "fail",
            //   content: res.msg ?? "登录过期",
            // });
          }
          return reject(res);
        }

        return reject(res);
      })
      .catch((err) => {
        console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^", err);
        let res = err.response;
        let code = (res || {}).status;
        if (code == 403) {
          // window.location.href =  domainHref + '/user/login'
          // this.$router.push({path: '/user/storage/list/1'});
          return reject(code);
        }

        return reject(res);
      });
  });
};

nzHttp.install = function (app) {
  app.config.globalProperties.$nzHttp = nzHttp;
};

export default nzHttp;
