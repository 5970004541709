import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53f99160"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src", "poster", "preload", "controls"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_image = _resolveComponent("v-image")!

  return (_openBlock(), _createElementBlock("div", {
    class: "v-image",
    ref: "container",
    style: _normalizeStyle(_ctx.containerStyle)
  }, [
    (_ctx.videoSrc)
      ? (_openBlock(), _createElementBlock("video", {
          key: 0,
          ref: "video",
          preload: "auto",
          style: {"display":"none"},
          onLoadeddata: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.videoLoad && _ctx.videoLoad(...args))),
          src: _ctx.videoSrc
        }, null, 40, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "v-image-container",
      style: _normalizeStyle(_ctx.containerImageStyle)
    }, [
      (_ctx.imageSrc&&!_ctx.useVideo&&_ctx.status !== _ctx.imageStatus.fail)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.imageSrc,
            onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLoadSuccess && _ctx.onLoadSuccess(...args))),
            onError: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onLoadFail && _ctx.onLoadFail(...args))),
            ref: "image",
            alt: "image",
            class: _normalizeClass(["v-image-style", _ctx.imageClassStyle]),
            style: _normalizeStyle(_ctx.imageStyle)
          }, null, 46, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.imageSrc&&_ctx.useVideo&&_ctx.status !== _ctx.imageStatus.fail)
        ? (_openBlock(), _createElementBlock("video", {
            key: 1,
            src: _ctx.imageSrc,
            poster: _ctx.usePoster,
            preload: _ctx.usePoster?'meta':'auto',
            controls: _ctx.controls,
            "webkit-playsinline": "",
            controlslist: "nodownload nofullscreen noremoteplayback",
            oncontextmenu: "return false",
            onLoadeddata: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onLoadSuccess && _ctx.onLoadSuccess(...args))),
            onError: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onLoadFail && _ctx.onLoadFail(...args))),
            ref: "image",
            class: _normalizeClass(["v-image-style", _ctx.imageClassStyle]),
            style: _normalizeStyle(_ctx.imageStyle)
          }, null, 46, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("aside", {
            key: 2,
            class: "v-image-icon",
            style: _normalizeStyle(_ctx.containerIconStyle)
          }, [
            _createVNode(_component_v_image, {
              src: _ctx.icon,
              backgroundColor: "transparent",
              class: "v-image-icon-image",
              mode: _ctx.iconMode
            }, null, 8, ["src", "mode"])
          ], 4))
        : _createCommentVNode("", true)
    ], 4),
    _createElementVNode("div", {
      class: _normalizeClass(["v-image-slot", {'v-image-pointer':_ctx.pointer}])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_ctx.status === _ctx.imageStatus.success)
        ? _renderSlot(_ctx.$slots, "success", { key: 0 }, undefined, true)
        : _createCommentVNode("", true),
      (_ctx.status === _ctx.imageStatus.fail)
        ? _renderSlot(_ctx.$slots, "fail", { key: 1 }, undefined, true)
        : _createCommentVNode("", true),
      (_ctx.status === _ctx.imageStatus.loading)
        ? _renderSlot(_ctx.$slots, "loading", { key: 2 }, undefined, true)
        : _createCommentVNode("", true)
    ], 2)
  ], 4))
}