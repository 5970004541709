
export const componentType = {
    1:'chat',
    2:'rich-text',
    3:'video-list',
    // 5:'ranking',
    // 6:'div'
    // 3:'play-video'
}

export default {
    'pc':[
        {
            label:'话题互动',
            slot:'tab-0',
            component:'topic',
            icon: require('../images/tab/schedule.png'),
            selectIcon: require('../images/tab/schedule-s.png')
        },
        // {
        //     label:'公告栏',
        //     slot:'tab-1',
        //     component:'bulletin-board',
        //     icon: require('../images/tab/notice.png'),
        //     selectIcon: require('../images/tab/notice-s.png'),
        //     loading:true
        // },
    ],
    'mobile':[
        {
            label:'聊天互动',
            component:'chat',
            slot:'tab-0',
            icon: require('../images/tab/chat.png'),
            selectIcon: require('../images/tab/chat-s.png')
        },
        {
            label:'话题互动',
            slot:'tab-1',
            component:'topic',
            icon: require('../images/tab/schedule.png'),
            selectIcon: require('../images/tab/schedule-s.png')
        },
        // {
        //     label:'专家介绍',
        //     component:'expert-introduction',
        //     slot:'expert-introduction',
        //     hide:true
        // },
        // {
        //     label:'聊天互动',
        //     component:'chat',
        //     slot:'tab-0',
        //     icon: require('../images/tab/chat.png'),
        //     selectIcon: require('../images/tab/chat-s.png')
        // },
        // {
        //     label:'日程介绍',
        //     slot:'tab-1',
        //     scroll:true,
        //     icon: require('../images/tab/schedule.png'),
        //     selectIcon: require('../images/tab/schedule-s.png')
        // },
        // {
        //     label:'公告栏',
        //     slot:'tab-2',
        //     component:'ranking',
        //     icon: require('../images/tab/notice.png'),
        //     selectIcon: require('../images/tab/notice-s.png'),
        //     loading:true
        // },
    ]
}
