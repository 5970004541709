import handle from './handle';
import questionnaire from "./questionnaire";
import trigger from '@/mixins/trigger';
import modal from './modal';
import player from './player';
import socket from "./socket";
import status from '$mixins/status';
import gift from './gift';
import size from './size';
import role from './role';
import stream from "./stream";

export default [handle,status,questionnaire,trigger,modal,player,socket,gift,size,role,stream];
