export default {
    // backgroundColor:{
    //     type:String,
    //     default:'rgba(0,0,0,0.5)'
    // },

    // title:{
    //   type:String,
    //   default:''
    // },

    value:{
        type:Boolean,
        default: false
    },

}
