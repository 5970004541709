<template>
  <a-modal
    v-model:visible="visible"
    title="投票"
    @ok="handleOk"
    :footer="null"
    class="vote-ant-modal-content"
  >
    <div :loading="isLoading">
      <div class="vote-item-group wrap">
        <div class="vote-item-title" v-text="voteTitle"></div>
        <div class="flex-1 rowACenter relative vote-target-item-content">
            <span
              class="ant-progress-text"
              style="margin-left: -8px; margin-right: 8px"
              >{{ voteScore }}%</span
            >
            <!-- ="#" -->
            <a-progress
              strokeColor="#f5992b"
              :percent="voteTotal"
              :success-percent="voteScore"
              :format="() => (voteFormat) + '%'"
            />
          <div class="absolute vote-target-item-disabled"></div>
        </div>
      </div>
      <div class="vote-btn-wrap">
        <!-- :class="{
            'bulletin-button-disabled': !submitStatus,
            'vote-btn-active': submitStatus,
          }" -->
        <div
          class="vote-btn vote-btn-left"
          style="display: block"
          :loading="submitLoading"
          @click="voting('YES')"
          type="primary"
        >
          <span class="vote-btn-content" :style="isVoted && isVoted == 'YES' ? 'padding-left:0':''"
            ><CheckOutlined
              class="btn-icon"
              v-show="isVoted && isVoted == 'YES'"
            />YES</span
          >
          <img class="vote-btn-img" :src="require('../images/left.png')" />
        </div>
        <!-- >{{ statusConst.success === status ? "已投票" : "投票" }}</a-button -->
        <!-- :class="{
            'bulletin-button-disabled': !submitStatus,
            'vote-btn-active': submitStatus,this.$data.isVoted
          }" -->
        <div
          class="vote-btn vote-btn-right"
          style="display: block"
          :loading="submitLoading"
          @click="voting('NO')"
          type="primary"
        >
          <span class="vote-btn-content" :style="isVoted && isVoted == 'NO' ? 'padding-left:0':''"
            ><CheckOutlined
              class="btn-icon"
              v-show="isVoted && isVoted == 'NO'"
            />NO</span
          >
          <img class="vote-btn-img" :src="require('../images/right.png')" />
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import mixins from "../mixins";
import ScrollView from "$components/scroll-view/src/main";
import { Button, Radio, Checkbox, Progress, Modal } from "ant-design-vue";
import { CheckOutlined } from "@ant-design/icons-vue";
import VImage from "$components/v-image/lib/main";
import popup from '$utils/tool/popup';
import props from "../props";
export default {
  
  name: "vote",
  components: {
    CheckOutlined,
    [Modal.name]: Modal,
    VImage,
    ScrollView,
    [Button.name]: Button,
    [Radio.name]: Radio,
    [Radio.Group.name]: Radio.Group,
    [Checkbox.name]: Checkbox,
    [Checkbox.Group.name]: Checkbox.Group,
    [Progress.name]: Progress,
  },
  props,
  mixins,
  data() {
    return {
      id: undefined,
      nzVoteListeningId: undefined,
      // visible: false,
      submitLoading: false,
      // loading: false,
      isLoading: false,

      // 投票开始前不让投
      disabled: false,

      //等直播加载完
      VoteUsePlayerInitialization: false,

      //投过票了
      isVoted: undefined,

      //投票内容
      voteTitle: "省钱是一门技术活，存钱更是，你的存钱速度有多快!",
      voteResult: '',
      voteTotal: '',
      voteScore: '',
      voteFormat: '',
    };
  },
  computed: {
    visible: {
      // getter
      get: function () {
        return this.$props.value && (!this.disabled) && this.VoteUsePlayerInitialization;
      },
      // setter
      set: function (newValue) {
        this.changeValue(newValue);
      },
    },
  },
  watch:{
    '$props.value': function(nV){
      console.log('nV', nV);
        if(nV){
          this.getVoteInfo()
        }
    }
  },
  created() {
    window.nzGlobalVoteUsePlayerInitialization = (val)=>{
      this.VoteUsePlayerInitialization = val
    }
    // 注册 投票监听
    if(window.nzGlobalVoteListeningId){
      clearInterval(window.nzGlobalVoteListeningId)
    }
    window.nzGlobalVoteListeningId = setInterval(()=>{
      this.$nzHttp('getVoteStep').then(res=>{
        const { vote } = (res||{})
        if(vote){
          this.disabled = false
          if(!vote.isVoted && !sessionStorage.getItem(vote.voteId+vote.createdAt)){
            // 没投过票  没谈过这个问题的窗
            this.getVoteInfo()
            this.visible = true
            sessionStorage.setItem(vote.voteId+vote.createdAt, vote.voteId);
          }
        }else{
          this.disabled = true
        }
      })
    // }, 888)
    }, 3333)

    // this.$globalEvent.nzVoteListeningStop

  },
  methods: {
    checkDisabled(){
      if(this.disabled){
        popup.$toast('投票未开启')
        return false
      }else{
        return true
      }
    },
    getVoteInfo(){
      if(!this.$data.isLoading){
        this.isLoading = true
        this.$nzHttp("voteLast", {
          // data: {
          // },
        }).then((res) => {
          const {vote, option} = res
          if(vote){
            // 如果有了
            // this.clearVoteListening()

            this.$data.id = vote.voteId
            this.$data.voteTitle = vote.title

            const voteResult = (vote.voteResult || {})
            const nV = {YES: voteResult.YES ?? 0, NO: voteResult.NO ?? 0}
            const voteTotal =  nV.YES + nV.NO
            this.$data.isVoted = option
            this.$data.voteResult = nV;

            // this.$data.voteTotal = nV.YES !==0 && nV.NO!==0 ? voteTotal : 100;
            this.$data.voteTotal = 100;
            this.$data.voteScore = nV.YES !==0 && nV.NO!==0 
              ? Math.floor((nV.YES/voteTotal)*100)
              : nV.YES ===0 && nV.NO === 0 
                  ? 50
                  : nV.YES === 0
                    ? 0
                    :nV.NO ===0
                      ? 100
                      //占位
                      : 0;
            this.$data.voteFormat = Math.floor(100-this.$data.voteScore);
            
            console.log('this.$data.voteScore', this.$data.voteScore);
          }
        }).catch(()=>{
          console.log('getVoteInfo has error');
        }).finally(()=>{
          this.isLoading = false
        });
      }
    },
    clearVoteListening() {
      clearInterval(window.nzGlobalVoteListeningId);
    },
    changeValue(value) {
      if (this.vValue !== value) {
        this.vValue = value;
      }
      if (this.value !== value) {
        return this.$emit("update:value", value);
      }
    },

    close() {
      return this.changeValue(false);
    },

    /**
     * 投票
     */
    voting(val) {
      if(this.$data.isVoted){
        console.log('this.$data.isVoted', this.$data.isVoted);
        return popup.$toast('请勿重复投票');
      }
      if (!this.isLoading) {
        this.isLoading = true
        this.$nzHttp("voting", {data: {
          voteId: this.$data.id,
          option: val
        }}).then((res) => {
          popup.$toast('投票成功');
          this.visible = false
        }).finally(()=>{
          this.isLoading = false
        });
      }
    },
  },
};
</script>

<style scoped src="../style.scss" lang="scss"></style>
