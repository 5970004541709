<template>
  <img src="../images/loading.gif" class="v-loading" />
</template>

<script>
export default {
  name: "v-loading",
};
</script>

<style scoped lang="scss">
.v-loading {
  // width: 224px;
  // height: 117px;
  width: 112px;
  height: 58px;
  // width: 56px;
  // height: 29px;
}

@media screen and (max-width: 768px) {
  .v-loading {
    width: 56px;
    height: 29px;
  }
}
</style>
