import inputData from '../data/input';
import verification from '$utils/verification';
import constName from '$utils/request/const/index';
import popup from "$utils/tool/popup";
export default {

    data(){
        return {
            // background: require('../images/background.png'),
            background: 'https://img.yunkust.com/img/images/background.png',
            mobileBackground: require('../images/mobile-background.png'),
            inputData:inputData(),
            buttonLoading:false
        }
    },

    inject:['loginSuccess'],

    methods:{

        submit(){

            verification.verificationPromise(this.inputData,true).then((data)=>{

                let stringify = JSON.stringify(data);

                if (this.reponseData && stringify === this.reponseData.data) {
                    return popup.$toast(this.reponseData.msg);
                }

                        // // 设置用户信息
                        // this.$store.commit('setUserInfo',data);
                        // // 设置登录成功
                        // return this.loginSuccess(true,true);
                this.buttonLoading = true
                this.$nzHttp('login', {
                    data: {
                        username: data.name,
                        email: data.email
                    },
                }).then((res)=>{
                    const {token} = res
                    data.token = token

                    localStorage.setItem("nz_token", token)

                    data.id = data.id || +new Date()+'-'+Math.floor(Math.random()*1000)
                    localStorage.setItem('unique',data.id);
                    // 设置用户信息
                    this.$store.commit('setUserInfo',data);
                    // 设置登录成功
                    return this.loginSuccess(true,true);
                }).finally(()=>{
                    this.buttonLoading = false
                });

            });

        }

    }

}
