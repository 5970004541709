<template>
  <section
    class="screen flex tipic"
    :class="$store.state.config.mobile ? 'topic-mobile' : 'topic-pc'"
  >
    <div class="flex-1 relative" style="min-height: 100px">
      <div
        class="absolute absolute-screen center"
        v-if="loading || (start && status === constStatus.loading)"
      >
        <a-spin size="large"></a-spin>
      </div>
      <div
        class="absolute topic-empty absolute-screen center"
        v-else-if="
          start && topicList.length <= 0 && status === constStatus.none
        "
      >
        <img src="../images/empty.png" class="chat-empty-icon" />
        <div class="chat-empty-text">暂无消息</div>
      </div>
      <scroll-view
        ref="scroll"
        :behavior="$store.state.config.mobile ? '' : 'none'"
        >
        <!-- @scroll-lower="getHistory" -->
        <div
          class="overflow"
          :class="[!loading ? 'chat-animate-show' : 'chat-animate-hide']"
        >
          <template v-for="item in topicSortList">
            <component
              :key="'chat-' + item.item.id"
              :isMy="item.item.visitor_id === userInfo.hash_id"
              :is="item.type"
              v-if="
                !messageTopData[item.item.id] && item.item.gift === undefined && item.isTopic
              "
              :item="item.item"
              :type="item.messageType"
              @setInputData="setInputData"
            ></component>
          </template>

        </div>
      </scroll-view>
    </div>
    <footer class="chat-footer rowACenter">
      <div class="flex-1 expression-input-wrap aCenter">
        <input
          v-if="taboo"
          disabled
          placeholder="您已被禁言"
          class="screen expression-input"
        />
        <form
          class="screen"
          v-else-if="$store.state.config.mobile"
          @submit.prevent="topicSendMessage"
        >
          <!-- ID：nzTopicInput 业务逻辑强相关 -->
          <input
            id="nzTopicInput"
            ref="input"
            :disabled="loading"
            v-model="message"
            :maxlength="maxLength"
            placeholder="我来说几句..."
            class="screen expression-input"
          />
        </form>
        <input
          v-else
          ref="input"
          id="nzTopicInput"
          :disabled="loading"
          v-model="message"
          @keydown.enter="topicSendMessage"
          :maxlength="maxLength"
          placeholder="我来说几句..."
          class="screen expression-input"
        />
      </div>
      <div
        class="button expression-button expression-button-disabled"
        v-if="taboo"
      >
        禁言
      </div>
      <div
        @click="topicSendMessage"
        class="button expression-button"
        :class="{ 'expression-button-disabled': loading }"
        v-else
      >
        发送
      </div>
    </footer>
  </section>
</template>

<script>
import { scrollView } from "$components";
import expression from "../../expression";
import components from "../components";
import { Popover, Spin } from "ant-design-vue";
import modal from "$components/modal";
import mixins from "../mixins";
import props from "../props";
// import queueGift from "../../live/components/queue-gift";
import QueueHighlightGift from "@/pages/home/components/live/components/queue-highlight-gift/src/main";
export default {
  name: "topic",
  inject: ["toggleExpressionModal"],
  components: {
    QueueHighlightGift,
    scrollView,
    ...components,
    expression,
    modal,
    [Popover.name]: Popover,
    [Spin.name]: Spin,
  },
  mixins,
  props,
  mounted() {
    // console.log('this.$refs', this.getInputRef);
    // console.log('this.$refs.input', this.$refs.input);
    // window.nzGetTopicInputRef = ()=>()=>{
    //   console.log('this.$refs', this.$refs.input);
    //   return this.$refs.input
    // }
    // this.getInputRef()
    setTimeout(this.getHistory, 400);
  },
  methods: {
    setInputData(val) {
      this.message = val;
    },
  },
};
</script>

<style scoped lang="scss" src="../style.scss"></style>
