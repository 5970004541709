<template>
  <div
    class="gift-tab overflow"
    :class="{ 'gift-tab-mobile': $store.state.config.mobile }"
  >
    <div
      class="gift-tab-container row"
      :style="{ transform: 'translateX(' + (pageStatus ? -50 : 0) + '%)' }"
    >
      <div class="flex-1">
        <tab :data="tabData">
          <template v-slot:header="{ data, select, trigger }">
            <!-- <header class="gift-tab-header row">
              <aside
                v-for="(item, index) in data"
                :key="'gift-tab-item-' + index"
                class="gift-tab-item flex-1 center"
                :class="{
                  'cursor-pointer': index !== select,
                  'gift-tab-item-active': index === select,
                }"
                @click="trigger(index)"
              >
                {{ item.label }}
              </aside>
            </header> -->
            <div></div>
          </template>

          <template
            v-for="(item, index) in tabData"
            v-slot:[item.slot]
            :key="'component-' + index"
          >
            <component
              :giftData="giftData"
              :rankData="rankData"
              :key="'gift-tab-' + index"
              :is="item.component"
            ></component>
          </template>
        </tab>
      </div>
      <div class="flex-1">
        <div class="screen gift-detail flex">
          <header class="gift-detail-header rowACenter between">
            <div @click="closeDetail" class="cursor-pointer">Back</div>
            <div class="rowACenter" v-if="giftItem.id">
              <div class="gift-detail-name">{{ giftItem.name }}</div>
              <img :src="giftItem.logo" class="gift-detail-icon" />
            </div>
          </header>
          <div class="flex-1 gift-detail-content">
            <flat-list
              :first="false"
              @fetch="fetchGiftDetail"
              ref="flat"
              empty-text="No gift record"
            >
              <template v-slot:layout="{ data }">
                <div class="gift-detail-title">
                  Cumulative reward：{{ giftItem.count || 0 }} individual
                </div>
                <aside
                  class="gift-detail-item rowACenter"
                  v-for="(item, index) in data"
                  :key="'gift-' + index"
                >
                  <img class="gift-detail-avatar" :src="item.avatar" />
                  <div class="flex-1">{{ item.user_name }}</div>
                  <div class="gift-detail-number">
                    {{ item.donate_count }} individual
                  </div>
                </aside>
              </template>
            </flat-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tab, FlatList } from "$components";
import gift from "../../gift";
import giftRanking from "../../gift-ranking";
import tabData from "../data/data";
import props from "../props";
import mixins from "../mixins";
export default {
  name: "gift-tab",
  data() {
    return {
      tabData: tabData(),
    };
  },
  components: {
    tab,
    gift,
    giftRanking,
    FlatList,
  },
  props,
  mixins,
};
</script>

<style scoped lang="scss" src="../style.scss"></style>
