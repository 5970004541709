export default {

    data(){
        return {
            streamList:[],
            streamItem:{}
        }
    },

    methods:{
        setStreamList(streamList){

            streamList.forEach(item=>{
                if (item.is_main) {
                    this.streamItem = Mudu.Room.GetPlayAddrList(item.stream_id);
                    this.streamItem.stream_id = item.stream_id;
                }
            });

            this.streamList = streamList;
        },
        switchStream(item){
            if (!this.currentLiveStatus) return;
            if (this.streamItem.stream_id === item.stream_id) return;
            // 返回视频回看配置
            let streamItem = Mudu.Room.GetPlayAddrList(item.stream_id);

            this.streamItem.stream_id = [...streamItem];
            this.streamItem.stream_id = item.stream_id;

            this.player.player.load(streamItem);
        }
    }

}