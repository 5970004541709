import instructions from './create';

// import globalConfig from '$config/config';

// import toast from '/tool/toast/index';

import InConfig from './config';

import popup from '$utils/tool/popup';

import store from '@/store/index';

import {
    InstructionsWhere,
    InstructionsTypes,
    LibRequestStatus,
    InstructionsMessageType
} from '../../const/request';
import user from "$config/user";
import constName from "$utils/request/const";

export default instructions.pushConfig({

    failMessage:{
        trigger:function (data) {

            let {responseData } = data;

            let statusCode:number | string = responseData ? responseData.status : 'timeout';

            // console.log(statusCode);

            let failMessageConfig = InConfig.getTargetConfig(data.requestData.mode,'failMessage');

            return popup.$toast(failMessageConfig[statusCode] || failMessageConfig.default,'fail');
            // return toast.fail(globalConfig.failMessage[statusCode] || globalConfig.failMessage.default);
        },
        where:InstructionsWhere.hasCustom,
        whereTrigger:function (data:LibRequestContext) {
            return data.status === LibRequestStatus.fail;
        }
    },

    format:{
        trigger:function(data){
            let {requestData,responseData} = data;
            let value = requestData.format(responseData.data);
            if (value !== undefined) {
                responseData.data = value;
            }
        },
        zIndex:2,
        where:InstructionsWhere.hasCustom,
        whereTrigger:function (data) { return data.status === LibRequestStatus.success }
    },

    successCode:{
      trigger:function(data){

          let {requestData,responseData} = data;

          // console.log(InConfig.getTargetConfig(requestData.mode,'successCode'));
          let successCode:Array<number> = requestData.successCode || InConfig.getTargetConfig(requestData.mode,'successCode');

          responseData.data.isSuccess = successCode.indexOf(responseData.data.code) >= 0;

      },
      zIndex:3,
      where:InstructionsWhere.custom,
      whereTrigger:function (data) { return data.status === LibRequestStatus.success }
    },

    message:{
        trigger:function (data){

            let {requestData,responseData} = data;

            let type:InstructionsMessageType =  typeof requestData.message === 'boolean' ? InstructionsMessageType.auto : requestData.message;
            let messageType:'success' | 'info';

            // if (
            //     (type === InstructionsMessageType.auto || type === InstructionsMessageType.success)
            //     &&
            //     responseData.data.isSuccess
            // ) {
            //     messageType = 'success';
            // } else if ((type === InstructionsMessageType.auto || type === InstructionsMessageType.other) && !responseData.data.isSuccess) {
            //     messageType = 'info';
            // }

            // return toast[messageType] && toast[messageType](responseData.data.msg);
            // return messageType && popup.$toast(responseData.data.msg,messageType);
            String(responseData.data.code)!=='0' && popup.$toast(responseData.data.msg, 'error');
            return String(responseData.data.code)==='0'
        },
        where: InstructionsWhere.hasCustom,
        whereTrigger:function (data) { return data.status === LibRequestStatus.success }
    },

    next:{
      triggerType:InstructionsTypes.all,
      trigger:function (data) {

          let {requestData,responseData,status} = data;

          let isSuccess:boolean = false;
          if (status === LibRequestStatus.success && responseData.data.isSuccess) {
              isSuccess = true;
          }

          return requestData.next({
              status:status === LibRequestStatus.loading,
              speed:status,
              isSuccess
          });

      }
    },

    loginCallback:{
      where: InstructionsWhere.custom,
      whereTrigger:function ({responseData}) {
          return responseData.data && responseData.data.code === 301;
      },
      trigger({responseData,requestData}){

          if(requestData.login !== false && user.isLogin()) {
              popup.$loginOut(responseData.data.msg || '登录失效');
          } else {
              store.dispatch('outLogin');
          }

      },
      zIndex:6
    },

    successAgent:{
        trigger(data){
            return data.resolve(data.responseData.data);
        },
        where: InstructionsWhere.custom,
        whereTrigger:function (data){
            return data.status === LibRequestStatus.success;
        },
        zIndex:-10
    },

    failAgent:{
        trigger(data){
            return data.reject({
                fail:true,
                responseData:data.responseData
            });
        },
        where: InstructionsWhere.custom,
        whereTrigger:function (data){
            return data.status === LibRequestStatus.fail;
        },
        zIndex:-10
    }

},{triggerType:InstructionsTypes.end})
