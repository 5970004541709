export default {


    data(){
        return {
            expressionModal:false,
            voteModal:false,
            giftModal:false,
            shareModal:false,
            sharePopoverModal:false,
            bulletinBoardModal:false,
            noticeModal:false,
            noticeContent:''
        }
    },

    provide(){
      return {
          toggleExpressionModal:()=> this.toggleExpressionModal(),
          toggleGiftModal:()=> this.toggleGiftModal(),
          hideExpressionModal:()=> this.toggleExpressionModal(),
          toggleShareModal:()=> this.toggleShareModal(),
          toggleBulletinBoardModal:()=> this.toggleBulletinBoardModal(),
          openNoticeModal:(content)=> this.openNoticeModal(content)
      }
    },

    methods:{

        openNoticeModal(content){
            this.noticeContent = content;
            this.noticeModal = true;
        },

        toggleVoteModal(){
            const i = this.$refs.vote && this.$refs.vote.checkDisabled();
            if (i) {
                this.voteModal = !this.voteModal;
            }
        },
        toggleExpressionModal(){
            if (this.loading) return;
            this.expressionModal = !this.expressionModal;
        },
        toggleGiftModal(){
            this.giftModal = !this.giftModal;
        },
        toggleShareModal(){
            this.shareModal = !this.shareModal;
            if (this.shareModal && this.sharePopoverModal) {
                this.sharePopoverModal = false;
            }
        },
        toggleBulletinBoardModal(){
            this.bulletinBoardModal = !this.bulletinBoardModal;
        }
    },
    mounted() {

    }

}
