// import {DateFormat} from "$utils/tool/date";
// import {PlayerMessage, RoomChatType} from "@/pages/home/components/chat/components/const";
// import {nextTick} from "vue";
import constName from "$utils/request/const/index";

export default {
  data() {
    return {
      // 设置页码
      page: 1,
      // 页码数量
      pageSize: 25,
      // 是否为初始化
      start: true,
    };
  },

  watch: {
    // loading(){
    //     if (!this.loading) {
    //
    //         this.setStatus(this.status === this.constStatus.loading ?  this.constStatus.none : this.status);
    //
    //         if (this.start && this.status === this.constStatus.success) {
    //             this.start = false;
    //         }
    //
    //     }
    // },
  },
  mounted() {
    // @ts-ignore
    window.nzTopicReplyToTopic = this.replyToTopic
    // @ts-ignore
    window.nzTopicTriggerPushMessage = this.triggerTopicPushMessage
  },
  methods: {
    setEmpty() {
      if (this.start && this.status === this.constStatus.loading) {
        this.setStatus(this.constStatus.none);
      }
    },

    initHistory() {
      this.page = 1;
      this.start = true;
    },
    // 获取历史消息记录
    getHistory(event) {
      if (
        this.status === this.constStatus.loading ||
        this.status === this.constStatus.none
      )
        return;

      this.setStatus(this.constStatus.loading);

      if (event && event.preventDefault) {
        event.preventDefault();
      }

      // @ts-ignore
      let commentPage =
        ((Mudu.Room && Mudu.Room.Topic && Mudu.Room.Topic.GetPage()) || 1) + 1;
      if (this.pageAll === undefined) {
        this.pageAll = commentPage;
      }

      // let i = 0
      // while (this.pageAll) {

      // }
      // let a = this.agoraRTMIM.getTopicHistory()
      // console.log('this.getTopicHistory', a);
      // console.log('this.pageAll', this.pageAll);
      // console.log('this.pageAll - this.page', this.pageAll - this.page);

      this.agoraRTMIM
        .getTopicAchievedQuotaHistory(this.pageAll - this.page)
        .then((data) => {
          
          if (this.$refs.scroll) {
            let config = this.$refs.scroll.getScrollConfig("y", false);
          }
          // let scrollEnd = config.maxScroll;
          let message = false;

console.log('data.comments', data.comments);

          if (data.comments) {
            this.$nzHttp("topicLikeTotal", {
              data: {
                commentId: data.comments.map((item) => item.id),
              },
            }).then((res) => {
              
              if(res && res.total){
                const { total } = res
                // console.log('res', res);
                //{
                //     total:{2747912: 1}
                // }
                Object.keys(total).forEach((item) => {
                  data.comments.map((cItem) => {
                    if (String(cItem.id) === String(item)) {
                      cItem.likeCount = total[item];
                    }
                  });
                });
              }
              for (let i = 0; i < data.comments.length; i++) {
                if (
                  this.triggerTopicPushMessage(
                    data.comments[i].msg_type,
                    data.comments[i],
                    true
                  )
                ) {
                  message = true;
                }
              }
            });
            // 执行排序
            // data.comments.sort((item, cItem)=> {
            //     if (item.top) {
            //         return 9999999999999;
            //     } else {
            //         return  cItem.dateline -item.dateline;
            //     }
            // })
          }

          if (this.page <= 1) {
            this.scrollToEnd();
          }
          // else if (message){
          //     if (event && event.preventDefault) {
          //         event.preventDefault();
          //     }
          //     nextTick(()=> this.scrollTo(scrollEnd));
          // }

          if (data.setPage <= 0) {
            this.page++;
          } else {
            this.page += data.setPage + 1;
          }

          if (data.page <= 1 || data.comments.length <= 0) {
            if (data.comments.length > 5) {
              if (this.start) {
                this.start = false;
              }
            }
            this.setStatus(this.constStatus.none);
          } else {
            if (this.start) {
              this.start = false;
            }
            this.setStatus(this.constStatus.success);
          }
          !message && this.getHistory(event);
        })
        .catch((e) => {
          this.setStatus(this.constStatus.fail);
        });
    },
  },
};
