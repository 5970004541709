import Player from '$utils/class/Player/index';
import {createApp, nextTick} from 'vue';
import mobileLiveControl from '../components/mobile-live-control';
import user from '$config/user';
import {PlayerMessage} from "$utils/class/Player/const";
export default {

    data(){
      return {
          live_num:0,
          countDown:undefined,
          Themes:{
              name:'',
              live_img:'',
              banner:'',
              cover_img:''
          },
          loading:false,
          initLoading: false,
          liveResultStatus:false,
          currentLiveStatus:false,
          activeItem:{},
          failText:''
      }
    },

    provide(){
      return {
          toQuestionnaire:(data)=> {
            //  this.controlRole.question = true;
            //  this.controlRole.vote = true;
            //  this.controlRole.gift = true;
             if (data.dialog_mode === 1) {
                 // @ts-ignore
                 if (window.getTargetCookieKey('skip_signup_'+data.question_id) !== '1') {
                     this.bulletinBoardModal = true;
                 }
             }
          },
          addLoginCallback:(callback)=> {
              callback && this.loginCallbackMany.push(callback);
          },
          setMobileLiveStatus:()=> {
              this.setMobileLiveTargetStatus();
          },
          createPlayLiveVideo:()=> {
              this.createPlayLiveVideo();
          }
      }
    },

    methods:{
        initTopic(){
            console.log('this.$refs.topic', this.$refs.topic);
            
            if (this.$refs.topic) {
                this.$refs.topic.initTopic();
            }
        },
        getChatRef(){
            return this.$refs.chat
        },
        installPlayer(){
          if(!this.player.initialized){
            this.player.initialized = true
            this.loading = true;
            this.initLoading = true;

            // @ts-ignore
            this.player.init(window.channelId).then(()=>{
              
                this.installBeforeControlRole();

                this.initLoading = false;

                // 渲染播放器
                this.createPlayLiveVideo();

                let roomInfo = this.player.getRoomInfo();
                this.Themes.name = roomInfo.name;
                this.Themes.live_img = roomInfo.live_img;
                try {
                    this.Themes.cover_img = roomInfo.themes[0].cover_img;
                    this.Themes.banner = roomInfo.themes[0].banner;

                } catch (e) {

                }
                this.changeFavicon(this.Themes.cover_img);

                // 获取自定义菜单栏
                this.setMenus(this.player.getMenus());

                this.setStreamList(this.player.getStreamList());

                // 获取观众人数
                this.live_num = this.player.getViewNum();

                // 获取倒计时
                this.countDown = this.player.getCountdown();

                // 设置获取历史记录
                nextTick(()=> {
                    if (this.$refs.topic) {
                        this.$refs.topic.getHistory();
                        // setTimeout(()=>{
                        //     this.$refs.topic.setEmpty();
                        // },5000);
                    }
                    if (this.$refs.chat) {
                        this.$refs.chat.getHistory();
                        setTimeout(()=>{
                            this.$refs.chat.setEmpty();
                        },5000);
                    }
                    // console.log('this.$store.state.config.mobile', this.$store.state.config.mobile);
                    // if(this.$store.state.config.mobile ) {
                    //     // @ts-ignore
                    //     if(window.nzGetChatRef){
                    //         // @ts-ignore
                    //         clearInterval(window.nzGetChatRef)
                    //     }
                    //     let i = 10
                    //     // @ts-ignore
                    //     window.nzGetChatRef = setInterval(()=>{
                    //         if(i--){
                    //             // @ts-ignore
                    //             // const chatRef = this.getChatRef()
                    //             // @ts-ignore
                    //             const chatRef = window.nzGetChatCTX
                    //             console.log('this.$refs.chat', chatRef.getHistory);
                    //             if(chatRef){
                    //                 chatRef.getHistory();
                    //                 setTimeout(()=>{
                    //                     chatRef.setEmpty();
                    //                 },5000);
                    //                 // @ts-ignore
                    //                 clearInterval(clearInterval(window.nzGetChatRef))
                    //             }
                    //         }else{
                    //             // @ts-ignore
                    //             clearInterval(window.nzGetChatRef)
                    //         }
                    //     }, 500)
                    // }
                    this.loginCallbackMany && this.loginCallbackMany.map((callback)=>{
                        callback && callback();
                    });
                    this.loginCallbackMany = [];

                });

                // 获取礼物信息
                this.getGiftList();

                // 登录 sdk
                this.loginSDK();

            }).catch((e)=>{
                console.log('installPlayer=>error', e);
            });
          }
        },

        createPlayLiveVideo(){

            this.player.remove();
            this.player.createPlayer({
                containerId:'player'
            },(config)=>{
                this.liveResultStatus = this.player.getLiveStatus();
                this.currentLiveStatus = this.player.getLiveStatus();
                if (this.$store.state.config.mobile) {
                    nextTick(()=> this.installMobileControl());
                }
                nextTick(()=>{
                  setTimeout(()=>{
                    // vote 出现控制
                    // @ts-ignore
                    if(window.nzGlobalVoteUsePlayerInitialization){
                      // @ts-ignore
                      window.nzGlobalVoteUsePlayerInitialization(true)
                    }
                  }, 5000)
                })
            });
        },

        changeFavicon(link){

            document.title = this.Themes.name;

            let $favicon = document.querySelector('link[rel="icon"]');
            if ($favicon !== null) {
                // @ts-ignore
                $favicon.href = link;
            } else {
                $favicon = document.createElement("link");
                // @ts-ignore
                $favicon.rel = "icon";
                // @ts-ignore
                $favicon.href = link;
                document.head.appendChild($favicon);
            }
        },

        // 登录
        loginSDK(){
            let userParams = {
                name:decodeURIComponent(user.user.name),
                avatar: user.user.avatar || 'https://static.mudu.tv/index/avatar.png',
                id: (user.user.id|| ''),
                assign_id: (user.user.id),
            }

            this.setMenus(this.player.getMenus());

            setTimeout(()=>{
              this.setMenus(this.player.getMenus());
          },3000);

        //   console.log('userParams.name', userParams.name);
        //   userParams.name 123
          
        //   console.log('userParams.avatar', userParams.avatar);
        //   player.ts:164 userParams.avatar https://static.mudu.tv/index/avatar.png
          
        //   console.log('userParams.assign_id', userParams.assign_id);
        //   player.ts:166 userParams.assign_id 123
          
            // 设置用户登录
            return this.player.login(
                userParams.name,
                userParams.avatar,
                userParams.assign_id.toString()
            ).then((data)=>{

                this.installControlRole();

                this.userInfo = data.user;
                // console.log('data.user', data.user);
                // {
                //     "id": 252440986,
                //     "nick": "123",
                //     "avatar": "https://static.mudu.tv/index/avatar.png",
                //     "wx_open_id": "",
                //     "wx_union_id": "",
                //     "qq_open_id": "",
                //     "wb_open_id": "",
                //     "dingding_open_id": "",
                //     "assign_id": "511795_123",
                //     "hash_id": "k2re520k",
                //     "outer_id": ""
                // }
                

                nextTick(()=> this.loading = false);
                // 设置用户登录成功
                return this.setStatus(this.constStatus.success);
            });
        },

        changeTrigger(item){
            this.activeItem = item.item;
            if(this.$store.state.config.mobile && item.item.slot === 'slot2'){
                setTimeout(this.initTopic, 400)
            }
        },

        installMobileControl(){
            let divElement = document.createElement('div');
            divElement.setAttribute('id','player-control');
            document.getElementById('player').appendChild(divElement);
            this.mobileContent = createApp(mobileLiveControl).mount('#player-control');
            this.mobileContent.watchCallback((status)=>{
                this.barrage = status;
            });
            this.setMobileLiveStatus();
        },

        // 设置移动端播放视频状态
        setMobileLiveTargetStatus(){
            if (this.mobileContent) {
                this.liveResultStatus = true;
                this.mobileContent.setLiveStatusNumber(true,this.player.getViewNum());
            }
        },

        // 设置移动端状态
        setMobileLiveStatus(){
            if (this.mobileContent) {
                this.liveResultStatus = this.player.getLiveStatus();
                this.mobileContent.setLiveStatusNumber(this.player.getLiveStatus(),this.player.getViewNum());
            }
        }

    },

    mounted() {

        this.player.on(PlayerMessage.streamEvent,(data)=>{
            this.setMobileLiveStatus();
            this.currentLiveStatus = this.player.getLiveStatus();
        });

        // return this.draw && this.loginSuccess(true);
    },

    created(){
        this.loginCallbackMany = [];
        this.player = new Player();
    },

    beforeUnmount() {
        delete this.mobileContent;
        this.player.off(PlayerMessage.login);
    }

}
