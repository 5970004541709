export default {

    // 默认缓存多久
    storageTime: 7 * 24 * 60 * 60,

    // 用户信息
    user:{},

    // 缓存的key
    storageKey:'user',

    // 校验唯一值
    stringify:''

} as any