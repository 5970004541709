<template>
  <div class="gift-container screen flex overflow"
    :class="{'gift-container-mobile': $store.state.config.mobile}"
  >
    <scroll-view class="screen flex-1 gift-scroll-content">
      <section class="row wrap gift-item-wrap">
        <div class="gift-item"
             v-for="(item,index) in giftData"
             :key="'gift-'+index"
        >
          <a-popover placement="top"
            :visible="giftModal[index]"
            @visibleChange="handleHoverChange(index,$event)"
            v-if="!$store.state.config.mobile"
          >
            <template v-slot:content>
              <div class="gift-content flex">
                <header class="gift-content-header rowACenter">
                  <v-image :src="item.icon" mode="center" background-color="transparent" class="gift-content-image"></v-image>
                  <div class="flex-1 gift-content-container flex">
                    <div class="gift-content-title">{{item.label}}</div>
                    <div class="aEnd">
                      <stepper v-model:value="giftNumber" disabled class="gift-content-stepper"></stepper>
                    </div>
                  </div>
                </header>
                <div class="flex-1 gift-content-footer rowACenter">
                  <div class="flex-1 row">total：<span>免费</span></div>
                  <div class="gift-button button"
                       :class="{'button-disabled':sendLoadingStatus}"
                       @click="sendGift(item)">{{ sendLoadingStatus?'赠送中':'赠送' }}</div>
                </div>
              </div>
            </template>
            <div class="screen center cursor-pointer gift-item-target"
                 :class="{'gift-item-check':giftIndex===index}"
                 @mouseenter="routerGift(index)"
            >
              <v-image :src="item.icon" mode="center" background-color="transparent" class="gift-image"></v-image>
              <div class="gift-title">{{item.label}}</div>
              <div class="gift-price">免费</div>
            </div>
          </a-popover>
          <div v-else class="screen center cursor-pointer gift-item-target"
               :class="{'gift-item-check':giftIndex===index}"
               @mouseenter="routerGift(index)"
          >
            <v-image :src="item.icon" mode="center" background-color="transparent" class="gift-image"></v-image>
            <div class="gift-title">{{item.label}}</div>
            <div class="gift-price">免费</div>
          </div>

        </div>
      </section>
    </scroll-view>
    <footer v-if="$store.state.config.mobile" class="gift-footer rowACenter">
      <stepper v-model:value="giftNumber" disabled class="gift-content-stepper"></stepper>
      <div class="flex-1"></div>
      <div class="gift-content-footer rowACenter">
        <div class="flex-1 row"><span>免费</span></div>
        <div class="gift-button button"
             :class="{'button-disabled':sendLoadingStatus}"
             @click="sendGift()">{{ sendLoadingStatus?'赠送中':'赠送' }}</div>
      </div>
    </footer>
  </div>
</template>

<script>
import mixins from '../mixins';
import {
  vImage,
  scrollView
} from '$components';
import stepper from '$components/stepper';
import props from '../props';
import {
  Popover,
  Button
} from 'ant-design-vue';
export default {
  name: "gift",

  components:{
    vImage,
    [Popover.name]:Popover,
    [Button.name]:Button,
    stepper,
    scrollView
  },

  props,

  mixins

}
</script>

<style scoped lang="scss" src="../style.scss"></style>
