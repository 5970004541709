import {RoomChatType} from '../components/const';
import {
    PlayerMessage
} from '$utils/class/Player/const';
import verification,{VerificationType} from '$utils/verification/index';
import Limit from '$utils/class/limit';
import popup from "$utils/tool/popup";
export default <LibMixins>{

    inject:['getSocket'],

    data(){
      return {
        //互动消息列表
        topicList: [],
        // 消息存储对象
        topicMessageData:{},
        // 消息排序
        messageDataOrder:[],
        // 时间
        messageTime:{},
        // 最大存储
        messageLength:300,
        // 消息
        message:'',
        // 最大输入
        maxLength:500
      }
    },
    computed:{
      topicSortList(){
          return this.topicList.sort((a, b) => {
            return (b.item.likeCount || 0) - (a.item.likeCount || 0);
          }).sort((a, b) => {
            const aV = a.item.is_admin
            const bV = b.item.is_admin
            if(aV && !bV){
              return -1;
            }else if(!aV && bV){
              return 1;
            }else {
              return 0;
            }
          })
      },
    },
    watch:{
        topicList: {
          handler(nV, oV) {
            console.log('change', nV)
          },
          deep: true
        }
    },
    provide(){
        return {
            addMessage:(message:string)=> this.addMessage(message)
        }
    },

    mounted() {

        this._messageDataOrder = {};

        // 聊天对象
        this.agoraRTMIM = this.getSocket();

        // 消息对象
        this.limit = new Limit();

        // 消息监听器
        this.messageCallback = function ({type,data}) {
            return this.triggerTopicPushMessage(type,data);
        }.bind(this);

        // 监听message 在全局监听监听过了
        // this.agoraRTMIM.on(PlayerMessage.message,this.messageCallback);

        // 删除消息
        this.messageDeleteCallback = function (data) {
            return this.deleteMessage(data);
        }.bind(this);

        // 监听删除消息
        this.agoraRTMIM.on(PlayerMessage.deleteMessage,this.messageDeleteCallback);
    },

    methods:{

      addMessage(useMessage:string){
          let message = this.message + useMessage;

          if (this.message !== message) {
              this.message = message;
          }
      },

      topicSendMessage(){
          console.log('this.message', this.message);
          
          if (verification.trigger[VerificationType.checkSpecialFormat](this.message)) {
              // 回复
              console.log('回复');
              this.sendTopicReply()
          }else{
              // 新的话题互动
              console.log('新的话题互动');
              this.sendTopic()
          }
      },
      getInputRef(){
          console.log('this.$refs.input', this.$refs.input);
      },
      initTopic(){
          this.topicList = []
          this.topicMessageData = {}
          this.initHistory()
          this.setStatus(this.constStatus.default)
          this.getHistory()
      },
      // 发送消息
      sendTopic(){
          if (this.loading) return ;

          if (verification.trigger[VerificationType.empty](this.message)) {

              if (this.limit.trigger()) {
                  let message = this.message;

                  if (message.length >= this.maxLength) {
                      message = message.substr(0,this.maxLength);
                  }

                  let resultData:Record<string, any> = {};
                  // 消息内容
                  resultData.message = message;
                  // 清空消息
                  this.message = '';
                  // 失去焦点
                  this.$refs.input && this.$refs.input.blur();
                  // 执行视图消息
                  // this.triggerTopicPushMessage(PlayerMessage.chat,resultData);
                  // 发送消息
                  return this.agoraRTMIM.sendTopic(resultData,PlayerMessage.chat).then(res=>{
                      // console.log('res', res);
                      // setTimeout(()=>{this.initTopic()}, 400)
                  }).catch((fail)=>{
                      if (fail===102) {
                          popup.$toast('您已被禁言','error');
                          this.taboo = true;
                      }
                  });
              } else {
                  return popup.$toast('发送过于频繁,请稍后再试');
              }
          }
      },
      // 发送消息
      sendTopicReply(){
          if (this.loading) return ;

          if (verification.trigger[VerificationType.empty](this.message)) {

              if (this.limit.trigger()) {
                  const messageData = this.message.split(/\@\]\s/, 3)
                  let message = messageData.length ? messageData[1] : ''
                    , resultData:Record<string, any> = {};

                  if (message.length >= this.maxLength) {
                      message = message.substr(0,this.maxLength);
                  }

                  // 消息内容
                  resultData.msg = message;
                  resultData.topicId = messageData[0].substr(2)
                  // 清空消息
                  this.message = '';
                  // 失去焦点
                  this.$refs.input && this.$refs.input.blur();
                  // 执行视图消息
                  // this.triggerTopicPushMessage(PlayerMessage.chat,resultData);
                  // 发送消息

                  return this.agoraRTMIM.sendTopicReply(resultData, PlayerMessage.chat).then((res)=>{
                      let item
                      this.topicList.some(val=>{
                          const i = String(val?.item?.id) === String(resultData.topicId)
                          if(i){
                              item=val.item
                              return i
                          }
                      })
                      console.log('item', item);
                      if(item){
                          let {nick} = this.agoraRTMIM.getUserInfo();
                          item.replies.push({
                              message:resultData.msg,
                              username: nick
                          })
  
                          const a = [...this.topicList]
                          this.topicList = []
                          setTimeout(()=>{
                              this.topicList = a
                          },400)
                      }
                  }).catch((fail)=>{
                      if (fail===102) {
                          popup.$toast('您已被禁言','error');
                          this.taboo = true;
                      }
                  });
              } else {
                  return popup.$toast('发送过于频繁,请稍后再试');
              }
          }
      },
      // 回复消息
      replyToTopic(resultData){
        let item
        this.topicList.some(val=>{
            const i = String(val?.item?.id) === String(resultData.visitor_id)
            if(i){
                item=val.item
                return i
            }
        })
        if(item){
            let {nick} = this.agoraRTMIM.getUserInfo();
            item.replies.push({
                message:resultData.msg,
                username: nick
            })

            const a = [...this.topicList]
            this.topicList = []
            setTimeout(()=>{
                this.topicList = a
            },400)
        }
      },
      // 获取历史消息记录
      // initTopicList(event){

      //     console.log('this.status', this.status);
      //     if(this.status === this.constStatus.loading || this.status === this.constStatus.none) return;
          
      //     this.setStatus(this.constStatus.loading);

      //     if (event && event.preventDefault) {
      //         event.preventDefault();
      //     }

      //     // @ts-ignore
      //     let commentPage = (Mudu.Room && Mudu.Room.Topic && Mudu.Room.Topic.GetPage() || 1) + 1;
      //     if (this.pageAll === undefined) {
      //         this.pageAll = commentPage;
      //     }

      //     let i = 0
      //     // while (this.pageAll) {
              
      //     // }
      //     // let a = this.agoraRTMIM.getTopicHistory()
      //     // console.log('this.getTopicHistory', a);
      //     console.log('this.pageAll', this.pageAll);
      //     console.log('this.pageAll - this.page', this.pageAll - this.page);
      // },
      // 添加消息
      pushTopicMessage(type,item,messageType,push:boolean=false){
          // console.log('itemitemitem', item);
          // {
          //     "id": 2747722,
          //     "visitor_id": "252440986",
          //     "userid": 252440986,
          //     "username": "123",
          //     "message": "233",
          //     "reply_to": "",
          //     "avatar": "https://static.mudu.tv/index/avatar.png",
          //     "act_id": 986784,
          //     "belong_to": 0,
          //     "checked": 1,
          //     "top": 0,
          //     "title": "",
          //     "is_admin": 0,
          //     "admin_type": 0,
          //     "created_at": "2024-05-16 14:25:53",
          //     "updated_at": "2024-05-16 14:25:53",
          //     "answer_id": 0,
          //     "videos_id": "",
          //     "videos_url": "",
          //     "videos_cover": "",
          //     "videos_deleted": 0,
          //     "replies": [],
          //     "thumbups": 0,
          //     "images": [],
          //     "is_rich_text": 0
          // }
          
          console.log('messageData', !this.topicMessageData[item.id]);
          

          if (!this.topicMessageData[item.id]) {

              const nV = ({
                  "type": "chat-message",
                  // "item": {
                  //     "id": 304142,
                  //     "visitor_id": "k2re520k",
                  //     "username": "233",
                  //     "title": "",
                  //     "is_admin": 0,
                  //     "dateline": 1715791303,
                  //     "checked": 1,
                  //     "pushed": 1,
                  //     "avatar": "https://static.mudu.tv/index/avatar.png",
                  //     "top": 0,
                  //     "priority": 10,
                  //     "message": "阿萨德",
                  //     "msg_type": 10,
                  //     "reply_count": 0,
                  //     "replies": [],
                  //     "replys": []
                  // },
                  "messageType": 10,
                  "isTopic": true,
                  // type,
                  item,
                  // messageType,
              })

              this.topicMessageData[item.id] = item;
              // this.$data.topicList.push
              // if (push) {
              //     this._messageDataOrder[useDateItem.message].push(item.id);
              //     this.messageTime[useDateItem.message].push({
              //         type,
              //         item,
              //         messageType
              //     });
              // } else {
              //     this._messageDataOrder[useDateItem.message].splice(1,0,item.id);
              //     this.messageTime[useDateItem.message].splice(1,0,{
              //         type,
              //         item,
              //         messageType
              //     });
              // }
              // DateFormat.insertAndSortByLikes(this.$data.topicList, nV)
              
              this.topicList.push(nV)
              
//我需要一个方法可以将对象插入到数组中，且插入时会根据对象的一个属性ofLikes的大小进行降序排序
              // if(!push) {
              //     this.sendMessageScroll();
              // }

              return true;
          }

          return  false;

      },

      // 执行发送消息
      triggerTopicPushMessage(type,item,push:boolean=false){
        console.log('item,item', item);
        

          if (this.status !== this.constStatus.success) {
              this.setStatus(this.constStatus.success);
          }

          // if (RoomChatType[type]) {

              // if (item.top) {
              //      this.pushTopMessage(RoomChatType[type],item,type,push);
              // }
              return this.pushTopicMessage(RoomChatType[type],item,type,push);
          // } else if (typeof item.msg_type === 'number'){

          //     // let richText =  this.agoraRTMIM.parseMessage(item);
          //     //
          //     // if (richText) {
          //     //     item.msg_type = PlayerMessage.richText;
          //     //     item.richText = richText;
          //     //     return  this.triggerTopicPushMessage(item.msg_type,item);
          //     // }

          // }
      },

      // 清空消息
      clearMessage(){
          if (this.topicMessageData.length > 0) {
              this.topicMessageData = [];
          }
      },

      // 删除消息
      deleteMessage(data){

          if (this.topicMessageData[data.id]) {
              let target = this.topicMessageData[data.id];

              delete this.topicMessageData[data.id];

              let useData = this._messageDataOrder[target];
              let useTarget = this.messageTime[target];

              if (useData && useTarget) {

                  let index = useData.indexOf(data.id);

                  useData.splice(index,1);

                  useTarget.splice(index  + 1,1);

                  if (useTarget.length <= 1) {
                      delete this.messageTime[target];

                      this.messageDataOrder.splice(
                          this.messageDataOrder.indexOf(target),
                          1
                      );
                  }

              }


          }
      }

  },
    beforeUnmount() {
        this.agoraRTMIM.off(PlayerMessage.message,this.messageCallback);
        this.agoraRTMIM.off(PlayerMessage.deleteMessage,this.messageDeleteCallback);
        delete this.messageCallback;
        delete this.messageDeleteCallback;
    }

}
