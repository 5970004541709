enum PlayerMessage {

    // 自定义
    custom='custom',

    // 聊天消息
    chat=10,

    // 聊天消息
    topic=88,

    // 表情消息
    expression=50,

    // 礼物消息
    gift=40,

    // 富文本
    richText='rich-text',

    // 登录
    login='login',

    // 时间自定义
    time='time',

    // 全部消息
    message='Comment.New',

    // 弹幕消息
    barrage='Barrage.New',

    // 禁言
    user='User.Mute',

    // 删除消息
    deleteMessage='Comment.Delete',

    // 开启直播或者暂停直播
    streamEvent='Room.StreamEvent',

    // 调查问卷状态
    question='Signup.Status.Change',

    // 投票状态
    vote='Vote.Changed',

    // 消息置顶
    top = 'Comment.Top',

    // 公告
    notice='activities.Announcement'

}

export {
    PlayerMessage
}
