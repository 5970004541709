import {RoomChatType} from '../components/const';
import {
    PlayerMessage
} from '$utils/class/Player/const';
import verification,{VerificationType} from '$utils/verification/index';
import {DateFormat} from '$utils/tool/date';
import Limit from '$utils/class/limit';
import popup from "$utils/tool/popup";
export default <LibMixins>{

    inject:['getSocket'],

    data(){
      return {
          // 消息存储对象
          messageData:{},
          // 消息排序
          messageDataOrder:[],
          // 时间
          messageTime:{},
          // 最大存储
          messageLength:300,
          // 消息
          message:'',
          // 最大输入
          maxLength:500
      }
    },

    provide(){
        return {
            addMessage:(message:string)=> this.addMessage(message)
        }
    },

    methods:{

        addMessage(useMessage:string){
            let message = this.message + useMessage;

            if (this.message !== message) {
                this.message = message;
            }
        },

        // 发送消息
        sendMessage(){
            
            if (this.loading) return ;

            if (verification.trigger[VerificationType.empty](this.message)) {

                if (this.limit.trigger()) {
                    let message = this.message;

                    if (message.length >= this.maxLength) {
                        message = message.substr(0,this.maxLength);
                    }

                    let resultData:Record<string, any> = {};
                    // 消息内容
                    resultData.message = message;
                    // 清空消息
                    this.message = '';
                    // 失去焦点
                    this.$refs.input && this.$refs.input.blur();
                    // 执行视图消息
                    // this.triggerPushMessage(PlayerMessage.chat,resultData);
                    // 发送消息
                    return this.agoraRTMIM.send(resultData,PlayerMessage.chat).catch((fail)=>{
                        console.log(fail);
                        if (fail===102) {
                            popup.$toast('您已被禁言','error');
                            this.taboo = true;
                        }
                    });
                } else {
                    return popup.$toast('发送过于频繁,请稍后再试');
                }

            }

        },

        // 添加消息
        pushMessage(type,item,messageType,push:boolean=false){

            if (!this.messageData[item.id]) {

                item.username = decodeURIComponent(item.username);
                // 执行设置间隔时间
                let date;
                if (this.lastDate) {
                    date = this.lastDate;
                } else {
                    date = new Date();
                }

                let nowDate = DateFormat(new Date(),'YYYY-MM-DD');
                let useDate = DateFormat(item.dateline,'YYYY-MM-DD');

                // 执行判断是否为同一天

                let diff = Math.abs(Math.floor(+date/1000) - item.dateline);

                if (diff >= (60 * 3)) {
                    this.lastDate = new Date(Number(item.dateline+'000'));
                }

                let format = nowDate===useDate ? 'hh:mm':'YYYY-MM-DD hh:mm';

                let diffDay = parseInt(nowDate.substr(8)) - parseInt(useDate.substr(8));
                if (diffDay == 1) {
                    format = '昨天 hh:mm'
                } else if (diffDay==2) {
                    format = '前天 hh:mm'
                }

                let useDateItem =  {
                    message: DateFormat(this.lastDate,format),
                    id: item.dateline + '-' + item.id
                };

                if (this.messageTime[useDateItem.message] === undefined) {
                    this.messageTime[useDateItem.message] = [
                        {
                            type: RoomChatType[PlayerMessage.time],
                            item:useDateItem,
                            messageType: PlayerMessage.time
                        }
                    ];
                    this.messageDataOrder[push?'push':'unshift'](useDateItem.message);

                    this._messageDataOrder[useDateItem.message] = [];

                }


                this.messageData[item.id] = useDateItem.message;
                if (push) {
                    this._messageDataOrder[useDateItem.message].push(item.id);
                    this.messageTime[useDateItem.message].push({
                        type,
                        item,
                        messageType
                    });
                } else {
                    this._messageDataOrder[useDateItem.message].splice(1,0,item.id);
                    this.messageTime[useDateItem.message].splice(1,0,{
                        type,
                        item,
                        messageType
                    });
                }


                // if(!push) {
                //     this.sendMessageScroll();
                // }

                return true;
            }

            return  false;

        },

        // 执行发送消息
        triggerPushMessage(type,item,push:boolean=false){

            if (this.status !== this.constStatus.success) {
                this.setStatus(this.constStatus.success);
            }

            if (RoomChatType[type]) {

                if (item.top) {
                     this.pushTopMessage(RoomChatType[type],item,type,push);
                }
                return this.pushMessage(RoomChatType[type],item,type,push);
            } else if (typeof item.msg_type === 'number'){

                // let richText =  this.agoraRTMIM.parseMessage(item);
                //
                // if (richText) {
                //     item.msg_type = PlayerMessage.richText;
                //     item.richText = richText;
                //     return  this.triggerPushMessage(item.msg_type,item);
                // }

            }
        },

        // 清空消息
        clearMessage(){
            if (this.messageData.length > 0) {
                this.messageData = [];
            }
        },

        // 删除消息
        deleteMessage(data){

            if (this.messageData[data.id]) {
                let target = this.messageData[data.id];

                delete this.messageData[data.id];

                let useData = this._messageDataOrder[target];
                let useTarget = this.messageTime[target];

                if (useData && useTarget) {

                    let index = useData.indexOf(data.id);

                    useData.splice(index,1);

                    useTarget.splice(index  + 1,1);

                    if (useTarget.length <= 1) {
                        delete this.messageTime[target];

                        this.messageDataOrder.splice(
                            this.messageDataOrder.indexOf(target),
                            1
                        );
                    }

                }


            }
        }

    },

    mounted() {

        this._messageDataOrder = {};

        // 聊天对象
        this.agoraRTMIM = this.getSocket();

        // 消息对象
        this.limit = new Limit();

        // 消息监听器
        this.messageCallback = function ({type,data}) {
            return this.triggerPushMessage(type,data);
        }.bind(this);

        // 监听message
        this.agoraRTMIM.on(PlayerMessage.message,this.messageCallback);

        // 删除消息
        this.messageDeleteCallback = function (data) {
            return this.deleteMessage(data);
        }.bind(this);

        // 监听删除消息
        this.agoraRTMIM.on(PlayerMessage.deleteMessage,this.messageDeleteCallback);
    },

    beforeUnmount() {
        this.agoraRTMIM.off(PlayerMessage.message,this.messageCallback);
        this.agoraRTMIM.off(PlayerMessage.deleteMessage,this.messageDeleteCallback);
        delete this.messageCallback;
        delete this.messageDeleteCallback;
    }

}
