import {Module} from "vuex";

import userConfig from '$config/user';

import storage from '$utils/tool/storage';

export default <Module<any,any>>{

    state:{
        user:{}
    },

    getters:{
        isLogin(state){
          const token= window.localStorage.getItem('nz_token')
          return !!(state.user && state.user.name && token);
        }
    },

    mutations:{

        initializationUser(state){
            let userData = storage.getItem(userConfig.storageKey);
            if (userData) {

                if (!userData.id) {
                    userData.id = localStorage.getItem('unique') || +new Date()+'-'+Math.floor(Math.random()*1000);
                    localStorage.setItem('unique',userData.id);
                }

                userConfig.user = userData;
                state.user = userData;
            }
        },

        // 设置缓存
        setUserInfo(state,user){

            let storageData = JSON.stringify(user);
            if (user && userConfig.stringify !== JSON.stringify(user)) {


                userConfig.stringify = storageData;

                userConfig.user = user;

                state.user = user;
                return storage.setItem(userConfig.storageKey,user,userConfig.storageTime);
            }

        },

        // 退出登录
        outLogin(state){

            userConfig.user = null;
            userConfig.stringify = null;
            state.user = {};
            return storage.removeItem(userConfig.storageKey);

        }

    },

    actions:{

        outLogin({ commit }){
            // 清除用户信息
            commit('outLogin');
        },

    }

}
