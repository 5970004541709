import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import "ant-design-vue/dist/antd.css";
import request from "$utils/request";
import nzHttp from "$utils/nz-http";

const app:any = createApp(App)
app.use(store)
  .use(request)
  .use(nzHttp)
  .mount("#useApp");
  
app.config.globalProperties.$globalEvent = new function(){}