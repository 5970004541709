import {PlayerMessage} from "$utils/class/Player/const";

export default {

    inject:['getSocket'],

    data(){
        return {
            taboo:false
        }
    },
    methods:{

        setTaboo(status){
            this.taboo = status;
        }
    },
    mounted() {
        // 删除消息
        this.messageTabooCallback = function (data) {
            if (data.isSelf) {
                this.setTaboo(data.mute);
            }
        }.bind(this);

        // 监听删除消息
        this.agoraRTMIM.on(PlayerMessage.user,this.messageTabooCallback);
    }

}
