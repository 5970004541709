/**
 * @return {string}
 */
const DateFormat = function (date, format = "YYYY-MM-DD hh:mm:ss") {
  if (typeof date !== "object" || date === null) {
    if (/^\d+$/.test(date)) {
      if (String(date).length <= 10) {
        date = date + "000";
      }
      date = new Date(Number(date));
    } else if (typeof date === "string") {
      if (date.indexOf("-") >= 0) date = date.replace(/-/g, "/");

      date = new Date(date);
    } else {
      date = new Date();
    }
  }

  return format.replace(DateFormat.reg, function (name) {
    let target = DateFormat.params[name[0]];
    if (target) {
      if (name === "YYYY") {
        if (date instanceof Date) {
          return date[target]();
        } else {
          return date[DateFormat.formatParams[name[0]]];
        }
      } else {
        let value;
        if (date instanceof Date) {
          value = date[target]();

          if (DateFormat.target[name[0]]) {
            value += DateFormat.target[name[0]];
          }
        } else {
          value = date[DateFormat.formatParams[name[0]]];
        }

        if (name.length >= 2) {
          value = value < 10 ? "0" + value : value;
        }

        return value;
      }
    } else {
      return name;
    }
  });
};

DateFormat.inverse = function (
  date: string | Date,
  format: string = "YYYY-MM-DD hh:mm:ss"
): Record<string, any> {
  let resultObject = {};
  format.replace(DateFormat.reg, function (name, start) {
    let useName = DateFormat.formatParams[name[0]];

    if (date instanceof Date) {
      let value = parseInt(date[DateFormat.params[name[0]]]());
      if (DateFormat.target[name[0]]) {
        value += DateFormat.target[name[0]];
      }

      resultObject[useName] = value;
    } else {
      resultObject[useName] = parseInt(date.substr(start, name.length));
    }

    return "";
  });

  return resultObject;
};

DateFormat.createMaxDate = function (date?: Date) {
  if (date === undefined) date = new Date();

  return new Date(date.getFullYear() + "/12/31 23:59:59");
};

DateFormat.createMinDate = function (date?: Date) {
  if (date === undefined) date = new Date();

  return new Date(date.getFullYear() + "/1/1 00:00:00");
};

DateFormat.formatParams = {
  Y: "year",
  M: "month",
  D: "date",
  h: "hour",
  m: "minute",
  s: "second",
};

DateFormat.params = {
  Y: "getFullYear",
  M: "getMonth",
  D: "getDate",
  h: "getHours",
  m: "getMinutes",
  s: "getSeconds",
};
/* 递增 */
DateFormat.target = {
  M: 1,
};

DateFormat.reg = /YYYY|M{1,2}|D{1,2}|h{1,2}|m{1,2}|s{1,2}/g;

DateFormat.insertAndSortByLikes = (
  arr: { ofLikes: number }[],
  newObj: { ofLikes: number }
) => {
  // 找到新对象应插入的位置
  let insertIndex = arr.findIndex(
    (item) => (newObj.ofLikes || 0) < (item.ofLikes || 0)
  );

  // 如果找不到合适的位置（即新对象的ofLikes大于所有现有对象），则将其添加到数组末尾
  if (insertIndex === -1) {
    insertIndex = arr.length;
  }

  // 使用splice方法在找到的位置插入新对象
  arr.splice(insertIndex, 0, newObj);

  return arr; // 返回更新后的数组
};

//   // 示例用法
//   let array = [
// 	{ ofLikes: 5 },
// 	{ ofLikes: 10 },
// 	{ ofLikes: 3 }
//   ];

//   let newItem = { ofLikes: 8 };
//   array = insertAndSortByLikes(array, newItem);
//   console.log(array); // 输出：[{ ofLikes: 10 }, { ofLikes: 8 }, { ofLikes: 5 }, { ofLikes: 3 }]

export default 1;

export { DateFormat };
