import {FunctionTrigger} from "$mixins/trigger/class";

export default [
    {
        label:'投票',
        icon: require('../images/control/vote.png'),
        // color:'#3c7ff2',
        trigger: new FunctionTrigger(function () {
            return this.toggleVoteModal();
        }),
        key:'vote'
    },
    // {
    //     label: '问卷',
    //     icon: require('../images/control/questionnaire.png'),
    //     // color:'#eac75c',
    //     trigger: new FunctionTrigger(function () {
    //         return this.toggleBulletinBoardModal();
    //     }),
    //     key:'question'
    // },
    {
        label: '礼物',
        icon: require('../images/control/gift.png'),
        // color:'#eac75c',
        is:'gift-tab',
        trigger: new FunctionTrigger(function () {
            if (this.$store.state.config.mobile) {
                return this.toggleGiftModal();
            }

        }),
        key:'gift'
    }
]
