import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    style: _normalizeStyle(_ctx.containerStyle),
    class: "scroll-container"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["scrollContainer", ['scroll-type-'+_ctx.scrollType]]),
      style: _normalizeStyle(_ctx.scrollContainer),
      ref: "scroll"
    }, [
      _createElementVNode("section", {
        class: "scrollContainerTake",
        ref: "scrollTake",
        style: _normalizeStyle({height:_ctx.minHeight})
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 4)
    ], 6)
  ], 4))
}