import Notice from "$utils/class/notice";
import { PlayerMessage } from "$utils/class/Player/const";

import encryption from "../../encryption/encryption";

export default class Socket extends Notice {
  constructor() {
    super();
  }

  // 安装消息监听器
  installSocketMessage() {
    // @ts-ignore
    Mudu.MsgBus.On(
      // 事件名，值为Comment.New
      PlayerMessage.message,

      // 事件处理函数，参数为新的评论，类型为object
      (newComment) => {
        return this.triggerMessage(this.parseSocket(JSON.parse(newComment)));
      }
    );
    // @ts-ignore
    Mudu.MsgBus.On(
      // 事件名，值为Topic.Reply.New
      'Topic.Reply.New', 
  
      // 事件处理函数，参数为新收到的reply
      function (reply) {
        reply = JSON.parse(reply)
        // console.log(reply)
        // {
        //   "id": 2748001,
        //   "visitor_id": "2zo96or8",
        //   "userid": 0,
        //   "username": "管理员",
        //   "message": "回复 test：22",
        //   "reply_to": "",
        //   "avatar": "https://cdn13.mudu.tv/assets/upload/165677455523108.jpg",
        //   "act_id": 986784,
        //   "belong_to": 2747996,
        //   "checked": 1,
        //   "top": 0,
        //   "title": "官方",
        //   "is_admin": 1,
        //   "admin_type": 0,
        //   "created_at": "2024-05-19 19:37:58",
        //   "updated_at": "2024-05-19 19:37:58",
        //   "answer_id": 0,
        //   "videos_id": "",
        //   "videos_url": "",
        //   "videos_cover": "",
        //   "videos_deleted": 0,
        //   "replies": [],
        //   "thumbups": 0,
        //   "images": [],
        //   "is_rich_text": 2
        // }
        // 添加数据
        // @ts-ignore
        console.log('window.nzTopicReplyToTopic', window.nzTopicReplyToTopic);
        // @ts-ignore
        if (window.nzTopicReplyToTopic) {
          // @ts-ignore
          window.nzTopicReplyToTopic(reply)
        }
      }
    )

    // @ts-ignore
    Mudu.MsgBus.On(
      // 事件名，值为Topic.New
      'Topic.New', 
  
      // 事件处理函数，参数为被置顶或者取消置顶的topic
      function (topic) {
        topic = JSON.parse(topic)
        console.log('Topic.New', topic)
        // @ts-ignore
          // @ts-ignore
        if (window.nzTopicTriggerPushMessage) {
          // @ts-ignore
          window.nzTopicTriggerPushMessage(PlayerMessage.chat, topic, true)
        }
      }
    )

    // 禁言
    // @ts-ignore
    Mudu.MsgBus.On(PlayerMessage.user, (dataStr) => {
      // 根据业务逻辑决定是否重新调用Mudu.Room.Signup.GetUsingSignup方法 或者关闭问卷的弹窗
      return this.triggerListener(PlayerMessage.user, dataStr);
    });

    // 监听问卷的使用状态变更， 需要在频道初始化完成后调用
    // @ts-ignore
    Mudu.MsgBus.On(PlayerMessage.question, (dataStr) => {
      // 根据业务逻辑决定是否重新调用Mudu.Room.Signup.GetUsingSignup方法 或者关闭问卷的弹窗
      return this.triggerListener(PlayerMessage.question, JSON.parse(dataStr));
    });

    // 事件会在投票状态改变(一般为后台关闭或开启投票)的时候被触发
    // @ts-ignore
    Mudu.MsgBus.On(PlayerMessage.vote, (data) => {
      data = JSON.parse(data);
      return this.triggerListener(PlayerMessage.vote, {
        status: this.getVoteTimeOver(data.data)
          ? false
          : !!data.data.vote_status,
      });
    });

    // @ts-ignore
    Mudu.MsgBus.On(
      // 事件名，值为Comment.New
      PlayerMessage.barrage,

      // 事件处理函数，参数为新的评论，类型为object
      (newComment) => {
        let resultData = this.parseSocket(JSON.parse(newComment));

        if (
          resultData.msg_type === PlayerMessage.chat ||
          resultData.msg_type === PlayerMessage.gift
        ) {
          if (resultData.msg_type === PlayerMessage.chat) {
            resultData._introduce = resultData.text;
          } else {
            resultData._introduce =
              resultData.gift.donate_info.user_name +
              " 送出 " +
              resultData.gift.donate_info.num +
              " 个 " +
              resultData.gift.donate_info.name;
          }

          // 触发 消息
          return this.triggerListener(PlayerMessage.barrage, {
            data: resultData,
            type: resultData.msg_type,
          });
        }
      }
    );

    // @ts-ignore
    Mudu.MsgBus.On(
      // 事件名，值为Room.StreamEvent
      PlayerMessage.streamEvent,
      // 事件处理函数，参数类型为object
      (data) => {
        return this.triggerListener(
          PlayerMessage.streamEvent,
          JSON.parse(data)
        );
      }
    );

    // @ts-ignore
    Mudu.MsgBus.On(
      // 事件名，值为Room.StreamEvent
      PlayerMessage.notice,
      // 事件处理函数，参数类型为object
      (data) => {
        return this.triggerListener(PlayerMessage.notice, data);
      }
    );

    // @ts-ignore
    Mudu.MsgBus.On(
      // 事件名，值为Comment.Delete
      PlayerMessage.deleteMessage,

      // 事件处理函数，参数为被删除的评论，类型为object
      (newComment) => {
        // // 触发 消息
        return this.triggerListener(
          PlayerMessage.deleteMessage,
          JSON.parse(newComment)
        );
      }
    );

    // @ts-ignore
    Mudu.MsgBus.On(
      // 事件名，值为Comment.Delete
      PlayerMessage.top,

      // 事件处理函数，参数为被删除的评论，类型为object
      (newComment) => {
        // // 触发 消息
        return this.triggerListener(
          PlayerMessage.top,
          this.parseSocket(JSON.parse(newComment))
        );
      }
    );
  }

  // 获取用户信息
  getUserInfo() {
    try {
      // @ts-ignore
      return Mudu.DB.datas.UserInfo.value;
    } catch (e) {
      return {};
    }
  }

  // 获取直播列表
  getStreamList(){
    return Mudu.Room.GetStreamList();
  }

  // 转换消息
  parseMessage(data) {
    try {
      // @ts-ignore
      return Mudu.Room.User.Room.Comment._resolveMsg(
        data.message,
        data.msg_type
      );
    } catch (e) {
      return "";
    }
  }

  // 解析
  parseSocket(data) {
    let endCode = encryption.decode(data.message || data.text || "");

    if (endCode) {
      try {
        endCode = JSON.parse(endCode);

        data = {
          ...data,
          ...endCode,
        };
        if (data.message === "我加入房间-" + data.username) {
          data.messageType = PlayerMessage.custom;
        }

        data.msg_type = data.messageType;
      } catch (e) {}
    }

    if (data.message === "我加入房间-" + data.username) {
      data.messageType = PlayerMessage.custom;
      data.msg_type = PlayerMessage.custom;
    }

    if (data.msg_type === PlayerMessage.chat) {
      this.replaceEm(data);
      let messageData = (data.message || "").split(/\n/) || [];
      if (messageData.length > 1) {
        data.messageArray = messageData;
      }
    }

    if (data && this.watch[data.msg_type]) {
      data = this.watch[data.msg_type](data);
    }

    return data;
  }

  watch = {
    [PlayerMessage.gift]: function (data) {
      data.gif = {};
      try {
        let message = JSON.parse(data.text || data.message);

        data.gift = message;
      } catch (e) {}
      return data;
    },
  };

  // 替换表情
  replaceEm(data) {
    if (!data.message) return;

    // 新GIF表情
    var emNewGifReg = new RegExp(/\[em_new_gif_([^]+)\]/g);

    if (emNewGifReg.test(data.message)) {
      data.src = data.message.replace(
        emNewGifReg,
        "https://static.mudu.tv/static/emoticons/emoticons-v2-gif/$1.gif"
      );
      data.msg_type = PlayerMessage.expression;

      return;
    }

    // 新png表情
    var emNewPngReg = new RegExp(/\[em_new_png_([^]+)\]/g);
    if (emNewPngReg.test(data.message)) {
      data.src = data.message.replace(
        emNewPngReg,
        "https://static.mudu.tv/static/emoticons/emoticons-v2-png/$1.png"
      );
      data.msg_type = PlayerMessage.expression;

      return;
    }

    // 新png表情
    var emPngReg = new RegExp(/\[em_([^]+)\]/g);
    if (emPngReg.test(data.message)) {
      data.src = data.message.replace(
        emPngReg,
        "https://static.mudu.tv/assets/img-console/activity/face/$1.png"
      );
      data.size = "small";
      data.msg_type = PlayerMessage.expression;

      return;
    }
  }

  // 触发消息
  triggerMessage(resultData, type = PlayerMessage.message) {
    // if (this.watch[resultData.messageType]) {
    //     resultData = this.watch[resultData.messageType](resultData);
    // }

    // 触发 消息
    this.triggerListener(type, {
      data: resultData,
      type: resultData.msg_type,
    });

    // 触发 消息类型
    this.triggerListener(resultData.msg_type, {
      type: resultData.msg_type,
      data: resultData,
    });
  }

  sendGift(data) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      let domain = Mudu.Room.User.Room.domain;
      // @ts-ignore
      let actHashId = Mudu.Room.User.Room.Comment.actHashId;
      // @ts-ignore
      return Fetcher.Post(
        domain + "/donate/api/v1/activity/" + actHashId + "/gift",
        JSON.stringify(data),
        function (data) {
          data = JSON.parse(data);
          if (data.errcode === 1000) {
            resolve(data);
          } else {
            reject();
          }
        },
        { "Content-Type": "application/json" }
      );
    });
  }

  // 获取倒计时
  getCountdown() {
    // @ts-ignore
    return Mudu.Room.User.Room.countdown;
  }

  // 获取调查问卷
  getQuestion() {
    return new Promise(function (resolve, reject) {
      // 获取当前频道正在使用的问卷数据， 需要在频道初始化完成后调用
      // @ts-ignore
      Mudu.Room.Signup.GetUsingSignup(function (dataStr) {
        // data 数据结果如下表所示
        return resolve(JSON.parse(dataStr));
      });
    });
  }

  // 提交调查问卷
  sendQuestion(data, question_id) {
    return new Promise(function (resolve, reject) {
      // 提交表单数据
      // @ts-ignore
      Mudu.Room.Signup.SubmitSingup(
        {
          code: "", // 必须 string 手机验证码，若无，则为空字符串
          signupId: question_id, // 必须 string 问卷id
          columns: data,
        },
        function (response) {
          // response 中对应的错误码见页面底部
          response = JSON.parse(response);
          resolve(response);
        }
      );
    });
  }

  // 点赞
  give(configId) {
    return new Promise(function (resolve, reject) {
      try {
        // @ts-ignore
        let domain = Mudu.Room.User.Room.domain;
        // @ts-ignore
        return Fetcher.Post(
          domain + "/like/api/v1/config/" + configId + "/like_it",
          JSON.stringify({
            count: 1,
            // @ts-ignore
            user_id: Mudu.Room.User.id,
          }),
          resolve,
          { "Content-Type": "application/json" }
        );
      } catch (e) {
        reject();
      }
    });
  }

  // 获取贡献榜
  getContribution(pageSize = 100) {
    return new Promise(function (resolve, reject) {
      try {
        // @ts-ignore
        let domain = Mudu.Room.User.Room.domain;
        // @ts-ignore
        let actHashId = Mudu.Room.User.Room.Comment.actHashId;

        // @ts-ignore
        return Fetcher.Get(
          domain +
            "/donate/api/v1/activity/" +
            actHashId +
            "/contribution?page=1&pageSize=" +
            pageSize,
          function (data) {
            data = JSON.parse(data);
            if (data.errcode === 1000) {
              resolve(data);
            } else {
              reject();
            }
          },
          { "Content-Type": "application/json" }
        );
      } catch (e) {
        reject();
      }
    });
  }

  // 获取公告
  getNotice() {
    return new Promise(function (resolve, reject) {
      try {
        // @ts-ignore
        let actHashId = Mudu.Room.User.Room.Comment.actHashId;
        // @ts-ignore
        return Fetcher.Get(
          "https://liveapi.mudu.tv/v2/micro-act/view/announcement?act_id=" +
            actHashId,
          function (data) {
            data = JSON.parse(data);
            if (data.errcode === 1000) {
              resolve(data);
            } else {
              reject();
            }
          },
          { "Content-Type": "application/json" }
        );
      } catch (e) {
        reject();
      }
    });
  }

  // 获取聊天排行榜
  getChatRanking(pageSize = 100) {
    return new Promise(function (resolve, reject) {
      try {
        // @ts-ignore
        let domain = Mudu.Room.User.Room.domain;
        // @ts-ignore
        let actHashId = Mudu.Room.User.Room.Comment.actHashId;
        // @ts-ignore
        return Fetcher.Get(
          domain +
            "/comments/api/activities/" +
            actHashId +
            "/rank?page=0&pageSize=" +
            pageSize,
          function (data) {
            data = JSON.parse(data);
            if (data.errcode === 1000) {
              resolve(data);
            } else {
              reject();
            }
          },
          { "Content-Type": "application/json" }
        );
      } catch (e) {
        reject();
      }
    });
  }

  // 获取投票信息
  getVoteInfo() {
    return new Promise((resolve, reject) => {
      Mudu.Room.Vote.Get(function (response) {
        response = JSON.parse(response);
        if (response.status === "y") {
          resolve(response.data);
        } else {
          reject();
        }
      });
    });
  }

  // 获取是否开启投票
  getVoteRole() {
    try {
      return !!(Mudu.Room.Vote.vote && Mudu.Room.Vote.vote.vote_status);
    } catch (e) {
      return false;
    }
  }

  // 获取是否截止
  getVoteTimeOver(data) {
    let endTime = +new Date(data.end_time.replace(/-/g, "/"));

    return +new Date() >= endTime;
  }

  // 获取投票
  getVoteRolePromise() {
    return new Promise((resolve) => {
      setTimeout(() => {
        return this.getVoteInfo()
          .then((data: any) => {
            if (data && data.act_id && data.vote_status) {
              return resolve(!this.getVoteTimeOver(data));
            } else {
              return resolve(false);
            }
          })
          .catch(() => resolve(false));
      }, 500);
    });
  }

  // 进行投票
  triggerVote(data) {
    return new Promise(function (resolve, reject) {
      Mudu.Room.Vote.Vote(
        // 问题及答案(数组)
        data,
        // 回调函数，参数为response
        function (response) {
          response = JSON.parse(response);
          resolve(response);
        },
        reject
      );
    });
  }

  videoInfo: Record<string, any> = {};
  // 根据视频的video_id 设置播放地址
  getVideoInfo(video_id) {
    return new Promise((resolve, reject) => {
      if (this.videoInfo[video_id])
        return resolve(this.videoInfo[video_id].url);

      // @ts-ignore
      Mudu.Room._getVideoInfo(video_id)
        .then((data) => {
          this.videoInfo[video_id] = { ...data };
          resolve(data.url);
        })
        .catch(reject);
    });
  }

  // 获取点赞总数
  getLickCount() {
    return new Promise(function (resolve, reject) {
      try {
        // @ts-ignore
        let domain = Mudu.Room.User.Room.domain;
        // @ts-ignore
        let actHashId = Mudu.Room.User.Room.Comment.actHashId;
        // @ts-ignore
        return Fetcher.Get(
          domain + "/like/api/v1/source/1/act/" + actHashId + "/count",
          function (data) {
            data = JSON.parse(data);
            if (data.errcode === 1000) {
              resolve(data);
            } else {
              reject();
            }
          },
          { "Content-Type": "application/json" }
        );
      } catch (e) {
        reject();
      }
    });
  }

  // 获取礼物配置
  getGiftConfig() {
    return new Promise(function (resolve, reject) {
      try {
        // @ts-ignore
        let domain = Mudu.Room.User.Room.domain;
        // @ts-ignore
        let actHashId = Mudu.Room.User.Room.Comment.actHashId;
        // @ts-ignore
        return Fetcher.Get(
          domain + "/donate/api/v1/activity/" + actHashId + "/config",
          function (data) {
            data = JSON.parse(data);
            if (data.errcode === 1000) {
              resolve(data);
            } else {
              reject();
            }
          },
          { "Content-Type": "application/json" }
        );
      } catch (e) {
        reject();
      }
    });
  }

  // 获取礼物排行榜
  getGiftRanking() {
    return new Promise(function (resolve, reject) {
      try {
        // @ts-ignore
        let domain = Mudu.Room.User.Room.domain;
        // @ts-ignore
        let actHashId = Mudu.Room.User.Room.Comment.actHashId;
        // @ts-ignore
        return Fetcher.Get(
          domain +
            "/donate/api/v1/activity/" +
            actHashId +
            "/config/detail?donate_type=2",
          function (data) {
            
            data = JSON.parse(data);
            if (data.errcode === 1000) {
              resolve(data);
            } else {
              reject();
            }
          },
          { "Content-Type": "application/json" }
        );
      } catch (e) {
        reject();
      }
    });
  }

  // 获取礼物赠送详情
  getGiftSendDetail(data) {
    return new Promise(function (resolve, reject) {
      try {
        // @ts-ignore
        let domain = Mudu.Room.User.Room.domain;
        // @ts-ignore
        let actHashId = Mudu.Room.User.Room.Comment.actHashId;
        // @ts-ignore
        return Fetcher.Get(
          domain +
            "/donate/api/v1/activity/" +
            actHashId +
            "/rank/detail?donate_id=" +
            data.id +
            "&page=" +
            data.page +
            "&page_size=" +
            data.pageSize,
          function (data) {
            data = JSON.parse(data);
            if (data.errcode === 1000) {
              resolve(data);
            } else {
              reject();
            }
          },
          { "Content-Type": "application/json" }
        );
      } catch (e) {
        reject();
      }
    });
  }

  // 发送消息
  send(data, type = PlayerMessage.chat) {
    return new Promise((resolve, reject) => {
      data.messageType = type;

      if (type === PlayerMessage.chat) {
        data = data.message;
      } else {
        data = encryption.encode(JSON.stringify(data));
      }

      // @ts-ignore
      Mudu.Room.User.Room.Comment.Send(
        // 要发送的评论文本，类型为string
        data,
        // 发送完成的回调函数，参数为response对象
        function (response) {
          response = JSON.parse(response);
          if (response.status === "y") {
            resolve(response);
          }
          if (response.status === "n") {
            reject(response.flag);
          }
        }
      );
    });
  }

  // 获取历史记录循环获取版本
  getAchievedQuotaHistory(page) {
    return new Promise((resolve, reject) => {
      return this.setAchievedQuotaData(page, page, [], resolve, reject);
    });
  }

  // 设置数据
  setAchievedQuotaData(startPage, page, data, resolve, reject) {
    return this.getMessageHistory(page)
      .then((resultData: any) => {
        resultData.comments.map((item) => {
          if (item.type !== "custom") {
            data.push(item);
          }
        });
        if (data.length >= 20 || page <= 1 || resultData.comments.length <= 0) {
          return resolve({
            page: resultData.page,
            comments: data,
            setPage: startPage - page,
          });
        } else {
          return this.setAchievedQuotaData(
            startPage,
            page - 1,
            data,
            resolve,
            reject
          );
        }
      })
      .catch(reject);
  }

  // 获取历史记录
  getMessageHistory(page) {
    return new Promise((resolve, reject) => {
      // 返回评论页数，类型为int

      try {
        // @ts-ignore
        // let commentPage = Mudu.Room && Mudu.Room.Comment && Mudu.Room.Comment.GetPage() || 1;

        // @ts-ignore
        Mudu.Room.Comment.Get(
          // 要获取评论的页码，类型为int
          page,
          // 评论获取成功的回调函数，参数为response对象
          (response) => {
            try {
              response = JSON.parse(response);
              if (response.status === "y") {
                if (response.data.comments) {
                  response.data.comments = response.data.comments.map(
                    (item) => {
                      return this.parseSocket(item);
                    }
                  );
                }

                return resolve(response.data);
              }
              if (response.status === "n") {
                reject();
              }
            } catch (e) {
              resolve({ comments: [] });
            }
          },
          function (fail) {
            resolve({ comments: [] });
          }
        );
      } catch (e) {
        resolve({ comments: [] });
      }
    });
  }

  // 获取历史记录循环获取版本
  getTopicAchievedQuotaHistory(page) {
    return new Promise((resolve, reject) => {
      return this.setTopicAchievedQuotaData(page, page, [], resolve, reject);
    });
  }

  // 设置数据
  setTopicAchievedQuotaData(startPage, page, data, resolve, reject) {
    console.log("setTopicAchievedQuotaData is run ");
    return this.getTopicHistory(page)
      .then((resultData: any) => {
        console.log('resultData', resultData);
        
        resultData.comments.map((item) => {
          if (item.type !== "custom") {
            data.push(item);
          }
        });
        if (data.length >= 20 || page <= 1 || resultData.comments.length <= 0) {
          return resolve({
            page: resultData.page,
            comments: data,
            setPage: startPage - page,
          });
        } else {
          return this.setTopicAchievedQuotaData(
            startPage,
            page - 1,
            data,
            resolve,
            reject
          );
        }
      })
      .catch(reject);
  }

  // 获取历史记录
  getTopicHistory(page) {
    return new Promise((resolve, reject) => {
      // 返回评论页数，类型为int

      let j = 100
      const i = setInterval(()=>{
        if(Mudu?.Room?.Topic?.Get){
          clearInterval(i)
          Mudu?.Room?.Topic?.Get(
            // 要获取评论的页码，类型为int
            page,
            // 评论获取成功的回调函数，参数为response对象
            (response) => {
              try {
                response = JSON.parse(response);
                if (response.status === "y") {
                  // if (response.data.topics) {
                  //     response.data.topics = response.data.topics.map((item)=>{
                  //         return this.parseSocket(item);
                  //     });
                  // }
  
                  if (response.topics) {
                    response.comments = response.topics.map((item) => {
                      return this.parseSocket(item);
                    });
                  }
  
                  return resolve(response);
                }
                if (response.status === "n") {
                  reject();
                }
              } catch (e) {
                resolve({ comments: [] });
              }
            },
            function (fail) {
              resolve({ comments: [] });
            }
          );
        }else{
          if(j--<0){
            clearInterval(i)
          }
        }
      }, 288)
    });
  }

  // 发送消息
  sendTopic(data, type = PlayerMessage.chat) {
    return new Promise((resolve, reject) => {
      data.messageType = type;

      // if (type === PlayerMessage.chat) {
      //     data = data.message;
      // } else {
      //     data = encryption.encode(JSON.stringify(data));
      // }

      // 这俩都有
      // console.log('Mudu.Room.User.Room.Topic', Mudu.Room.User.Room.Topic);
      // console.log('Mudu.Room.Topic', Mudu.Room.Topic);
      const reqData = {
        msg: data.message,
        // images: [
        //     'https://cdn13.mudu.tv/assets/upload/editor/150408081032166.png',
        //     'https://cdn13.mudu.tv/assets/upload/editor/150408082290971.png',
        //     'https://cdn13.mudu.tv/assets/upload/editor/150408084198886.png'
        // ],
      };
      // @ts-ignore
      Mudu.Room.User.Room.Topic.SendTopic(
        // 要发送的评论文本，类型为string
        reqData,
        // 发送完成的回调函数，参数为response对象
        function (response) {
          response = JSON.parse(response);
          if (response.status === "y") {
            resolve(response);
          }
          if (response.status === "n") {
            reject(response.flag);
          }
        }
      );
    });
  }

  // 发送消息
  sendTopicReply(data, type = PlayerMessage.chat) {
    return new Promise((resolve, reject) => {
      data.messageType = type;
      console.log("data", data);

      // data
      // {
      //     topicId: 4116,
      //     msg: '听说云导播台能做实时字幕，是真的吗'
      // },
      // @ts-ignore
      Mudu.Room.User.Room.Topic.SendReply(
        // 要发送的评论文本，类型为string
        data,
        // 发送完成的回调函数，参数为response对象
        function (response) {
          response = JSON.parse(response);
          if (response.status === "y") {
            resolve(response);
          }
          if (response.status === "n") {
            reject(response.flag);
          }
        }
      );
    });
  }
}
