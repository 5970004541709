<template>
  <section class="tab-container flex">
    <!--   头部组件   -->
    <slot
      name="header"
      :data="vTabData"
      :trigger="triggerTo"
      :select="vTabSelect"
    >
      <scroll-view
        class="tab-header-scroll"
        :class="{
          'tab-header-scroll-mobile': $store.state.config.mobile,
        }"
        ref="scroll"
        :scroll-x="true"
        :scroll-y="false"
      >
        <header
          class="rowACenter tab-header"
          :class="{ 'tab-header-mobile': $store.state.config.mobile }"
        >
          <slot
            name="header-item"
            v-for="(item, index) in vTabData"
            :item="item"
            :index="index"
            :trigger="triggerTo"
          >
            <aside
              :key="'scroll-index-' + index"
              :class="{
                'tab-header-margin': index !== 0,
                'tab-header-select': index === vTabSelect,
              }"
              class="cursor-pointer relative rowACenter tab-header-item"
              @click="triggerTo(index)"
              :ref="'menus' + index"
            >
              <!--            <img :src="index === vTabSelect ?item.selectIcon :item.icon" class="tab-header-icon" />-->
              <span class="relative">{{ item.label }}</span>
              <div
                v-if="index !== 0"
                class="absolute tab-header-auto-line"
              ></div>
            </aside>
            <div
              class="absolute tab-header-line center"
              v-if="width[vTabSelect]"
              :style="{
                transform: 'translateX(' + lineOffsetLeft + 'px)',
                width: width[vTabSelect] + 'px',
              }"
            >
              <div></div>
            </div>
          </slot>
        </header>
      </scroll-view>
    </slot>
    <!--   内容组件   -->
    <!-- <article class="overflow" -->
    <article
      :class="{
        'flex-1': !autoHeight && !autoHeightFixed,
        'auto-height-fixed': autoHeightFixed,
      }"
    >
      <swiper v-if="swipeOption" ref="swiper" :swiperOption="swipeOption">
        <swiper-item v-for="item in vTabData" :key="item.slot">
          <!-- <slot v-if="item.loading" :name="item.slot">{{item.slot}}--{{item.label}}</slot> -->
          <!-- <slot v-if="item.loading" :name="item.slot">{{ item.slot }}</slot> -->
          <slot :name="item.slot">{{ item }}</slot>
        </swiper-item>
      </swiper>
    </article>
  </section>
</template>

<script>
import props from "../props";
import mixins from "../mixins";
import swiper from "$components/swiper";
import swiperItem from "$components/swiper-item";
import ScrollView from "$components/scroll-view/src/main";
export default {
  name: "tab",
  props,
  mixins,
  components: {
    ScrollView,
    swiper,
    swiperItem,
  },
  // watch:{
  //   vTabData: {
  //     handler(nV, oV) {
  //       console.log('change', nV)
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // }
};
</script>

<style scoped lang="scss" src="../style.scss"></style>
