const SERVER_URL = "/api";
// const SERVER_URL = "https://roche.yunkust.com/api";

// path body query
export default {
  login: [SERVER_URL + "/user/login ", "post"],
  
  // 获取正在进行中的投票 轮询专用
  getVoteStep: [SERVER_URL + "/vote/polling", "post"],
  
  // 获取最近一次投票
  voteLast: [SERVER_URL + "/vote/latest", "post"],

  // 点赞数
  topicLikeTotal: [SERVER_URL + "/comment/like_total", "post"],

  // 点赞
  topicLike: [SERVER_URL + "/comment/like", "post"],

  // 投票 开始
  voteStart: [SERVER_URL + "/vote/start", "post"],
  // 投票 结束
  voteEnd: [SERVER_URL + "/vote/end", "post"],
  // 投票列表
  voteList: [SERVER_URL + "/vote/list", "post"],
  // 投票
  voting: [SERVER_URL + "/vote/vote", "post"],
};
