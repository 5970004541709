enum VerificationType {
    // 是否为空
    empty,
    // 手机号
    mobile,
    // 密码
    password,
    // 是否相等
    eq,
    // 身份证号
    id,
    // 邮箱地址
    email,
    // 长度
    length,

    //自定义规则
    checkSpecialFormat
}

enum LibDataComponents {
    text,
    input,
    date,
    address,
    textarea,
    upload,
    radio,
    picker,
    audio
}

export { VerificationType,LibDataComponents };
