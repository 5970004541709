import { componentType } from "../data/tab";
import request from "$utils/request";
export default {

    data(){
        return {
            goTopStatus:false,
            barrage:true,
            userInfo:{},
            tabMenu:[],
            initStartLoading:true,
            tabMenuOrder:{
                type:1
            }
        }
    },

    provide(){
        return {
            loginSuccess:()=> this.loginSuccess()
        }
    },

    watch:{
        tabMenus:{
          handler:function (nV){
            console.log('tabMenus nV', nV);
          },
          immediate:true,
          deep:true,
        }
    },

    computed:{
        tabMenus(){
            let tabData = this.tabData[ this.$store.state.config.mobile?'mobile':'pc'] || [];
            let resultTabData = [...tabData,...this.tabMenu]

            let uniqueObject = {};
            resultTabData.forEach((item)=>{
                uniqueObject[item.component] = item;
            });

            if (!this.$store.state.config.mobile) {
                delete uniqueObject['chat'];
            }


            return Object.values(uniqueObject);
        }
    },

    methods:{

        setMenus(menus){

            let tabMenus = [];

            this.storageInfo(menus);
            menus.map((item)=>{
                if (componentType[item.type]) {

                    tabMenus.push({
                        id:item.menu_id,
                        component: componentType[item.type],
                        label: item.name,
                        item:item,
                        hide: item.hide || false,
                        // slot: 'slot-'+item.menu_id,
                        loading: item.type === 1 ? true : undefined
                    })
                }
            });



            this.tabMenu = tabMenus.sort((item,beforeItem)=>{
                return item.item.order >= beforeItem.item.order ? 1 : -1;
            }).map((item, index)=>{
                item.slot = 'tab-'+index
                return item
            });;

            console.log(this.tabMenus);

        },

        // 执行缓存
        storageInfo(menus){
            localStorage.setItem(this.storageMenusKey,JSON.stringify({
                menus,
                themes:this.Themes
            }));
        },

        // 加载缓存
        initStorage(){
            let storageData = localStorage.getItem(this.storageMenusKey);
            if (storageData) {
                try {
                    let resultStorageData = JSON.parse(storageData);
                    resultStorageData.menus && this.setMenus(resultStorageData.menus);
                    this.Themes = resultStorageData.themes;
                    this.changeFavicon(this.Themes.cover_img);
                    this.initStartLoading = false;
                } catch (e) {

                }
            }
        },

        loginSuccess(must:boolean,first:boolean=false){
            if (this.status === this.constStatus.loading && !must) return;
            this.setStatus(this.constStatus.loading);

            if( window.location.href.indexOf('#sys-vote') < 0 ){
                // 开始加载
                return this.installPlayer(first);
            }
        },


        scroll(config){
            let status = config.y.y > config.y.height;
            if (this.goTopStatus !== status) {
                this.goTopStatus = status;
            }
        }
    },

    created() {

        // @ts-ignore
        this.storageMenusKey = 'live-'+window.channelId;

        this.initStorage();

        this.$store.commit('initializationUser');

      
    },

    mounted() {
        // 如果用户已登录
        if (this.$store.getters.isLogin) {
            return this.loginSuccess(true,true);
        }
    }

}
