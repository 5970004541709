
import popup from '$utils/tool/popup';
// import props from "../props";
import { Progress, Button } from "ant-design-vue";
export default {
  name: "sys-vote",
  components: {
    [Progress.name]: Progress,
    [Button.name]: Button,
  },
  //   props,
  data() {
    return {
      list: [
        {
          voteId: 1,
          title: "",
          option: [],
          voteResult: {
            YES: 0,
            NO: 0
          },
        },
      ],

      currentIndex: 0,
    };
  },
  computed: {
    currentData() {
      return this.list[this.$data.currentIndex];
    },
  },
  mounted() {
      // @ts-ignore
    if(window.nzGlobalSysVote){
      // @ts-ignore
      clearInterval(window.nzGlobalSysVote)
    }
    // @ts-ignore
    window.nzGlobalSysVote = setInterval(()=>{
      this.init();
    }, 888)
  },
  methods: {
    init() {
      this.$nzHttp("voteList").then((res) => {
        const { list } = res
        this.$data.list = list.map(item=>{
          const voteResult = (item.voteResult || {})
          const nV = {YES: voteResult.YES ?? 0, NO: voteResult.NO ?? 0}
          const voteTotal =  nV.YES + nV.NO
          const voteScore = nV.YES !==0 && nV.NO!==0 
              ? Math.floor((nV.YES/voteTotal)*100)
              : nV.YES ===0 && nV.NO ===0 
                  ? 50
                  : nV.YES === 0
                    ? 0
                    :nV.NO ===0
                      ? 100
                      //占位
                      : 0
          return {
            ...item,
            voteResult: nV,
            voteTotal: 100,
            voteScore,
            format: Math.floor(100-voteScore)
          }
        })
      });
    },
    triggerVoteState(id, val?:boolean){
      if(val){
        this.$nzHttp("voteStart",{ data: {
          voteId:id
        }}).then((res)=>{
          // console.log(res);
          popup.$toast('开始成功');
        })
      }else{
        this.$nzHttp("voteEnd",{ data: {
          voteId:id
        }}).then((res)=>{
          // console.log(res);
          popup.$toast('结束成功');
        })
      }
    },
    setCurrentIndex(val){
      this.$data.currentIndex = val
    }
  },
};
