<template>
  <div class="container-app-wrap"
    :class="{
      'container-screen-mobile': $store.state.config.mobile
    }"
  >
    <home></home>
    <!-- <home v-if="nzRootPage"></home> -->
    <!-- <system v-if="nzRootPage" /> -->
  </div>

</template>
<script>
import config from '$config/config';
import home from '$pages/home';
// import system from '$pages/system';
export default {
  components:{
    home,
    // system
  },
  // data() {
  //   return {
  //     nzRootPage: true,
  //   }
  // },
  // beforeCreate(){
  //   if(window.location.href.indexOf('/static/boao/')){
  //     //'boao'
  //     this.nzRootPage = true
  //   }
  //   if(window.location.href.indexOf('/static/sys/')){
  //     // 'sys'
  //     this.nzRootPage = false
  //   }
  // },
  created() {
    this.$request({
      url:'https://static.mudu.tv/static/websdk/sdk.js?v=1.1',
      method:'GET'
    })
    config.register(this);
    if (this.$store.state.config.mobile) {
      document.getElementById('useApp').setAttribute('class','useApp-mobile')
    }
  },
}
</script>
<style lang="scss">
@import "./pages/home/style/768";
  @import "assets/scss/entry/index";
  /* 微信分享二维码 */
  .weChat-share .ant-modal-footer,.weChat-share .ant-modal-header{
    display: none;
  }
  /* 微信分享二维码 */
  /* 加载 */
  .ant-spin-dot-item{
    background-color: $main !important;
  }
  /* 加载 */
  .mudu-background{
    background-size: cover !important;
  }
  //.mudu-video{
  //  object-fit: cover !important;
  //}

</style>
