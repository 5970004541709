<template>
  <section
    class="login-container screen jCenter aEnd"
    :class="$store.state.config.mobile ? 'login-container-mobile': 'login-container-pc'"
    :style="{
      backgroundImage:
        'url(' +
        ($store.state.config.mobile ? mobileBackground : background) +
        ')',
    }"
  >
    <div class="login-modal">
      <!-- <header class="login-title">ROCHE</header> -->
      <section class="login-input-wrap overflow" >
        <aside
          v-for="(item, index) in inputData"
          :key="'input-' + index"
          class="login-input-item rowACenter"
        >
          <label v-if="(!$store.state.config.mobile) && item.showLabelText" v-text="item.placeholder" class="login-input-label"></label>
          <img :src="item.icon" class="login-icon" />
          <div class="flex-1">
            <input
              v-model="item.value"
              @keydown.enter="submit"
              :placeholder="item.placeholder"
              :maxlength="item.maxLength || 50"
            />
          </div>
        </aside>
      </section>

      <a-button
        :loading="buttonLoading"
        @click="submit"
        type="primary"
        style="background-color: #f6982a!important;border: #f6982a!important"
        class="login-button"
        >登录/Login</a-button
      >
    </div>
  </section>
</template>

<script>
import mixins from "../mixins";
import { Button } from "ant-design-vue";
export default {
  name: "login",
  mixins,
  components: {
    [Button.name]: Button,
  },
};
</script>

<style scoped lang="scss" src="../style.scss"></style>
<style lang="scss">
.login-container {
  .ant-btn-primary,
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: $main !important;
    border-color: $main !important;
  }
}
</style>
